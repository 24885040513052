import React, { useRef, useEffect, useState, useMemo } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import FontawesomeMarker from "mapbox-gl-fontawesome-markers";
import * as turf from '@turf/turf';
import styled from 'styled-components';
import mapboxGl from 'mapbox-gl';
import 'overlayscrollbars/overlayscrollbars.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import _ from 'underscore';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from './../../constants/index';

import iconBed from '../../img/icon-bed.svg';
import iconElevator from '../../img/icon-elevator.svg';
import iconNurse from '../../img/icon-nurse.svg';
import iconShower from '../../img/icon-shower.svg';
import iconReception from '../../img/icon-reception.svg';
import iconLocker from '../../img/icon-locker.svg';
import iconHall from '../../img/icon-hall.svg';
import iconWC from '../../img/icon-WC.svg';
import iconWaitingRoom from '../../img/icon-waiting-room.svg';
import iconVip from '../../img/icon-vip.svg';
import labelCircle from '../../img/label-circle.svg';
import iconStairs from '../../img/icon-stairs.svg';
import iconLobby from '../../img/icon-lobby.svg';
import iconRestaurant from '../../img/icon-restaurant.svg';
import iconEatingRoom from '../../img/icon-eating-room.svg';
import iconPatientRoom from '../../img/icon-patient-room.svg';
import iconEntrance from '../../img/icon-entrance.svg';
import iconEnter from '../../img/icon-enter.svg';

import Contacts from './features/Contacts';
import Controls from './features/Controls';
import FiltersPanel from './features/FiltersPanel';
import Header from 'components/common/Header';

import { PaginationControl } from './features/PaginationControl';
import ReactSearchAutocomplete from './features/Search/ReactSearchAutocomplete';

import { getPointFromCenter } from '../../utils/geometry';
import { loadImage, getFeaturesOnMap } from '../../utils';
import { getAllPlans, getPlanTypes, getLayerParams, getFloors, getPlanGroupByType, getPlanGroupByDepartment, getRootCategories } from '../../layers/source';
import { getOuterBuildings, getOuterStreet, getOuterStreetName } from 'layers/source';
import { addFilter, resetFilters } from './../../store/filtersSlice';

import { useAppSelector, useAppDispatch } from './../../app/store';

import { mapLoadRasterizedImage  } from 'utils/index';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxGl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = () => {
    const [map, setMap] = useState(null);
    const [floor, setFloor] = useState(1);
    const [floors, setFloors] = useState(null);
    const [planTypes, setPlanTypes] = useState(null);
    const [planType, setPlanType] = useState(null);
    const [listOfPlanTypes, setListOfPlanTypes] = useState(null);
    const [roomId, setRoomId] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [planGroupByDepartment, setPlanGroupByDepartment] = useState(null);
    const [hasError, setError] = useState(false);
    const [layerParams, setLayerParams] = useState(null);
    const [plans, setPlans] = useState(null);
    const [outerBuildings, setOuterBuildings] = useState(null);
    const [outerStreet, setOuterStreet] = useState(null);
    const [outerStreetName, setOuterStreetName] = useState(null);
    const [plansGroupByType, setPlansGroupByType] = useState(null);
    const duration = useMemo(() => 300, []);
    const mapRef = useRef(null);
    const initFloorRef = useRef(null);
    const requestRef = useRef(0);
    const startTimeRef = useRef(0);
    const notMedicalCategory = 2;
    const notMedicacGroupTypes = [10, 29, 48, 9, 4, 14, 49, 17, 22, 12, 16, 51, 50, 48, 23, 52];
    const medicalCategory = 1;
    const [rootCategories, setRootCategories] = useState(null);
    const params = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const filtersList = useAppSelector((state) => state.filters.filters);

    useEffect(() => {
        if (Object.keys(params).length !== 0) {
            params.categories.split(',').map(item => {
                if (!(filtersList.indexOf(parseInt(item)) > -1)) return dispatch(addFilter(parseInt(item)));
            });
        }
    }, []);

    useEffect(() => {
        if (!map || !layerParams || !plans) return

        if (filtersList.length > 0) {
            navigate(ROUTES.MAP.replace(':categories', filtersList.join(',')));

            let features = getFeaturesOnMap(filtersList, plans.features, floor);

            if (features.length > 0) {
                let boundArea = features.map(elem => {
                    return elem.properties.geom_centroid.coordinates});

                const line = turf.lineString(boundArea);
                const bbox = turf.bbox(line);

                map.fitBounds(bbox, {
                    padding: { top: 20, bottom:20, left: 20, right: 20}
                });

                setPlanType(filtersList);
                initFloorRef.current?.(floor, filtersList);
            }
        } else {
            navigate(ROUTES.MAP.replace(':categories', ''));
            setPlanType(null);
            initFloorRef.current?.(floor, null);
        }
    }, [filtersList, plans, map]);

    useEffect(() => {
        (async () => {
            try {
                await Promise.all([
                    getLayerParams(),
                    getAllPlans(),
                    getFloors(),
                    getPlanGroupByType(),
                    getPlanGroupByDepartment(),
                    getPlanTypes(),
                    getRootCategories(),
                    getOuterBuildings(),
                    getOuterStreet(),
                    getOuterStreetName(),
                ])
                .then(([
                    dataLayerParams, dataPlans, dataFloors, dataPlansGroupByType, 
                    dataPlanGroupByDepartment, dataPlanTypes, rootCategories,
                    outerBuildings, outerStreet, outerStreetName,
                ]) => {
                    setLayerParams(dataLayerParams);
                    setPlans(dataPlans);
                    setFloors(dataFloors);
                    setPlansGroupByType(dataPlansGroupByType);
                    setPlanGroupByDepartment(dataPlanGroupByDepartment);
                    setListOfPlanTypes(dataPlanTypes);
                    setRootCategories(rootCategories);
                    setOuterBuildings(outerBuildings);
                    setOuterStreet(outerStreet);
                    setOuterStreetName(outerStreetName);
                }).catch((error) => {
                    console.error(error);
                    setError(true);
                });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN;

        if (!mapRef.current || !layerParams || !plans) return;

        let initialZoom = 18.46;

        const symbols = [
            { imageUrl: iconBed, id: 'icon-bed'},
            { imageUrl: iconElevator, id: 'icon-elevator'},
            { imageUrl: iconNurse, id: 'icon-nurse'},
            { imageUrl: iconShower, id: 'icon-shower'},
            { imageUrl: iconWC, id: 'icon-wc'},
            { imageUrl: iconWaitingRoom, id: 'icon-waiting-room'},
            { imageUrl: iconVip, id: 'icon-vip'},
            { imageUrl: iconStairs, id: 'icon-stairs'},
            { imageUrl: iconLobby, id: 'icon-lobby'},
            { imageUrl: iconReception, id: 'icon-reception'},
            { imageUrl: iconLocker, id: 'icon-locker'},
            { imageUrl: labelCircle, id: 'icon-circle'},
            { imageUrl: iconRestaurant, id: 'icon-cafe'},
            { imageUrl: iconEatingRoom, id: 'icon-dining-room'},
            { imageUrl: iconPatientRoom, id: 'icon-patient-room' },
            { imageUrl: iconEntrance, id: 'icon-entrance' }
        ];

        const map = new mapboxGl.Map({
            container: mapRef.current || '',
            center: layerParams.centroid,
            isScrollEnabled: false,
            zoom: initialZoom,
            minZoom: initialZoom,
            style: { version: 8, sources: {}, layers: [], glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf" },
            antialias: true
        });

        const popup = new mapboxGl.Popup({
            closeButton: false,
            className: "plan-tooltip"
        });

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            defaultMode: 'simple_select',
        });

        window.map = map;
        window.draw = draw;

        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        map.addControl(draw);

        const initFloor = (floor, planType = [], roomId = null) => {
            let mapRendered = false,
                departmentPoints = [];

            const colors = ['#75ACFF', '#1668F5'],
                departmentArray = ['#9CB7CE', '#95CDFF', '#ACF1FB', '#6FB1D6', '#93CDD8', '#ADCFDF', '#B6E0D6', '#A9F2DE', '#A9EEF2', '#C7ECEC', '#94A9D4', '#ABBDF2', '#B2C5E7', '#BFCDE9', '#C1F8FF', '#A1D5FF', '#74D9D3', '#71DAE8'],
                departmentDarkArray = ['#6E9EC7', '#4DA7F6', '#6ADAEA', '#1A92D6', '#57A8B8', '#61ABCE', '#68C2AC', '#74D5BA', '#67D5DB', '#7BD5D5', '#5B82D1', '#728DDD', '#628FDF', '#8398C2', '#70D5E2', '#51A7EC', '#50BDB7', '#31B9CB'];

            if (map.getLayer('base-bg-1')) map.removeLayer('base-bg-1');
            if (map.getLayer('base-bg-2')) map.removeLayer('base-bg-2');

            if (map.getLayer('plansGroupByDepartment-labels')) map.removeLayer('plansGroupByDepartment-labels');
            if (map.getLayer('plansGroupByDepartment-areas')) map.removeLayer('plansGroupByDepartment-areas');
            if (map.getLayer('plansGroupByDepartment-borders')) map.removeLayer('plansGroupByDepartment-borders');

            if (map.getLayer('plansGroupedByType-areas')) map.removeLayer('plansGroupedByType-areas');
            if (map.getLayer('plansGroupedByType-borders')) map.removeLayer('plansGroupedByType-borders');
            if (map.getLayer('plansGroupedByType-labels')) map.removeLayer('plansGroupedByType-labels');
            if (map.getLayer('plansGroupedByType-labels-rotated')) map.removeLayer('plansGroupedByType-labels-rotated');
            if (map.getLayer('plansGroupedByType-icons')) map.removeLayer('plansGroupedByType-icons');
            if (map.getLayer('plansGroupedByType-borders-highlighted')) map.removeLayer('plansGroupedByType-borders-highlighted');
            if (map.getLayer('plansGroupedByType-icons-enter')) map.removeLayer('plansGroupedByType-icons-enter');

            if (map.getLayer('plans-areas')) map.removeLayer('plans-areas');
            if (map.getLayer('plans-borders')) map.removeLayer('plans-borders');
            if (map.getLayer('plans-walls')) map.removeLayer('plans-walls');
            if (map.getLayer('plans-labels')) map.removeLayer('plans-labels');
            if (map.getLayer('plans-borders-highlighted')) map.removeLayer('plans-borders-highlighted');

            if (map.getLayer('outer-buildings')) map.removeLayer('outer-buildings');
            if (map.getLayer('outer-street')) map.removeLayer('outer-street');
            if (map.getLayer('outer-street-name')) map.removeLayer('outer-street-name');

            if (map.getSource('rooms')) map.removeSource('rooms');
            if (map.getSource('plans-walls')) map.removeSource('plans-walls');
            if (map.getSource('plansGroupByDepartment')) map.removeSource('plansGroupByDepartment');
            if (map.getSource('plansGroupByType')) map.removeSource('plansGroupByType');
            if (map.getSource('labelsGroupedByDepartment')) map.removeSource('labelsGroupedByDepartment');
            if (map.getSource('labelsGroupedByType')) map.removeSource('labelsGroupedByType');
            if (map.getSource('labelsOfPlans')) map.removeSource('labelsOfPlans');

            if (map.getSource('outer-buildings')) map.removeSource('outer-buildings');
            if (map.getSource('outer-street')) map.removeSource('outer-street');
            if (map.getSource('outer-street-name')) map.removeSource('outer-street-name');

            draw.deleteAll();

            const geojsonFeaturesGroupedByDepartment = {
                'type': 'FeatureCollection',
                'features': []
            };

            const geojsonFeaturesGroupedByType = {
                'type': 'FeatureCollection',
                'features': []
            };

            const geojsonFeaturesPlans = {
                'type': 'FeatureCollection',
                'features': []
            };

            plans.features.forEach((plan, index) => {
                geojsonFeaturesPlans.features.push({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': plan.properties.geom_centroid.coordinates
                    },
                    'properties': {
                        'bearing': plan.properties.bearing,
                        'priority': plan.properties.priority,
                        'floor_id': plan.properties.floor_id,
                        'name': plan.properties.name,
                        'geom_area': plan.properties.geom_area,
                        'type_id': plan.properties.type_id
                    }
                });
            });

            planGroupByDepartment.features.forEach((plan, index) => {
                geojsonFeaturesGroupedByDepartment.features.push({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': plan.properties.geom_centroid.coordinates
                    },
                    'properties': {
                        'floor_id': plan.properties.floor_id,
                        'geom_area': plan.properties.geom_area,
                        'department_name': plan.properties.department_name
                    }
                });
            });

            plansGroupByType.features.forEach((plan, index) => {
                geojsonFeaturesGroupedByType.features.push({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': plan.properties.geom_centroid.coordinates
                    },
                    'properties': {
                        'bearing': plan.properties.bearing,
                        'priority': plan.properties.priority,
                        'floor_id': plan.properties.floor_id,
                        'width': plan.properties.width,
                        'geom_area': plan.properties.geom_area,
                        'type_name': plan.properties.type_name,
                        'type_id': plan.properties.type_id,
                        'image': plan.properties.image
                    }
                });
            });

            map.addSource('rooms', {
                type: 'geojson',
                data: plans
            });

            map.addSource('plans-walls', {
                type: 'geojson',
                data: {
                    ...plans,
                    features: (
                        plans.features.map(f => ({ ...f, geometry: f.properties.geom_line }))
                    )
                }
            });

            map.addSource('plansGroupByType', {
                type: 'geojson',
                data: plansGroupByType
            });

            map.addSource('plansGroupByDepartment', {
                type: 'geojson',
                data: planGroupByDepartment
            });

            map.addSource('labelsGroupedByDepartment', {
                type: 'geojson',
                data: geojsonFeaturesGroupedByDepartment
            });

            map.addSource('labelsGroupedByType', {
                type: 'geojson',
                data: geojsonFeaturesGroupedByType
            });

            map.addSource('labelsOfPlans', {
                type: 'geojson',
                data: geojsonFeaturesPlans
            });

            map.addSource('outer-buildings', {
                type: 'geojson',
                data: outerBuildings,
            });

            map.addSource('outer-street', {
                type: 'geojson',
                data: outerStreet
            });

            map.addSource('outer-street-name', {
                type: 'geojson',
                data: outerStreetName
            });

            map.addLayer({
                id: 'outer-buildings',
                source: 'outer-buildings',
                type: 'line',
                paint: {
                    'line-color': '#bbb',
                    'line-width': 1,
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
            });

            map.addLayer({
                id: 'outer-street',
                source: 'outer-street',
                type: 'line',
                paint: {
                    'line-color': '#bbb',
                    'line-width': 1,
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
            });

            map.addLayer({
                id: 'outer-street-name',
                source: 'outer-street-name',
                type: 'symbol',
                paint: {
                    'text-color': '#bbb',
                    'text-halo-color': '#bbb'
                },
                layout: {
                    'text-field': ['get', 'name'],
                    "text-letter-spacing": 0.05,
                    'text-transform': 'uppercase',
                    'text-rotate': ['get', 'bearing'],
                    'text-font': ['Montserrat SemiBold', 'Arial Unicode MS Bold'],
                    'text-max-width': 10000,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 10,
                        19.0, 12,
                        19.5, 14,
                        20.0, 16,
                        20.5, 18,
                        21, 20,
                        21.5, 22
                    ],
                    'text-allow-overlap': true,
                    'icon-allow-overlap': true,
                }
            });
            
            if (_.contains([1, 2], floor)) {
                map.addLayer({
                    id: 'base-bg-1',
                    type: 'fill',
                    source: 'rooms',
                    filter: ['==', ['get', 'floor_id'], -1],
                    paint: {
                        'fill-color': '#fff',
                        'fill-opacity': 0.7
                    }
                });
            }

            if (_.contains([3, 4, 5, 6, 7, 8], floor)) {
                map.addLayer({
                    id: 'base-bg-1',
                    type: 'fill',
                    source: 'rooms',
                    filter: ['==', ['get', 'floor_id'], -2],
                    paint: {
                        'fill-color': '#fff',
                        'fill-opacity': 0.7
                    }
                });
            }

            map.addLayer({
                id: 'plansGroupByDepartment-labels',
                type: 'symbol',
                source: 'labelsGroupedByDepartment',
                'maxzoom': 18.8,
                'paint': {
                    'text-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        initialZoom,
                        'rgba(27, 32, 61, 0.6)',
                        22,
                        'rgba(27, 32, 61, 0.3)'
                    ],
                    'text-halo-width': 1,
                    'text-halo-color': 'white'
                },
                layout: {
                    'text-field': ['get', 'department_name'],
                    "text-letter-spacing": 0.03,
                    'text-line-height': 1.6,
                    'text-font': ['Montserrat Medium', 'Arial Unicode MS Bold'],
                    'text-transform': 'uppercase',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        initialZoom,
                        [ "*", [ "^", [ "-", 19.5, map.getZoom()], 1.5], 14],
                        19,
                        [ "*", [ "^", [ "-", 19.5, map.getZoom()], 1.5], 14]
                    ]
                },
                filter: ['==', ['get', 'floor_id'], floor],
            });

            map.addLayer({
                id: 'plansGroupByDepartment-borders',
                type: 'line',
                source: 'plansGroupByDepartment',
                filter: ['==', ['get', 'floor_id'], floor],
                'paint': {
                    'line-color': '#fff',
                    'line-offset': ['interpolate', ['linear'], ['zoom'], 18.8, 0, 18.85, -2],
                    'line-width': 3
                }
            }, 'plansGroupByDepartment-labels');

            map.addLayer({
                'id': 'plansGroupByDepartment-areas',
                'type': 'fill',
                'source': 'plansGroupByDepartment',
                'maxzoom': 18.8,
                'paint': {
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.8,
                        1,
                    ],
                    'fill-color': [
                        'case',
                        ['==', ['get', 'department_id'], 1],
                        departmentArray[0],
                        ['==', ['get', 'department_id'], 2],
                        departmentArray[1],
                        ['==', ['get', 'department_id'], 3],
                        departmentArray[2],
                        ['==', ['get', 'department_id'], 4],
                        departmentArray[3],
                        ['==', ['get', 'department_id'], 5],
                        departmentArray[4],
                        ['==', ['get', 'department_id'], 6],
                        departmentArray[5],
                        ['==', ['get', 'department_id'], 7],
                        departmentArray[6],
                        ['==', ['get', 'department_id'], 8],
                        departmentArray[7],
                        ['==', ['get', 'department_id'], 9],
                        departmentArray[8],
                        ['==', ['get', 'department_id'], 10],
                        departmentArray[9],
                        ['==', ['get', 'department_id'], 11],
                        departmentArray[10],
                        ['==', ['get', 'department_id'], 12],
                        departmentArray[11],
                        ['==', ['get', 'department_id'], 13],
                        departmentArray[12],
                        ['==', ['get', 'department_id'], 14],
                        departmentArray[13],
                        ['==', ['get', 'department_id'], 15],
                        departmentArray[14],
                        ['==', ['get', 'department_id'], 16],
                        departmentArray[15],
                        ['==', ['get', 'department_id'], 17],
                        departmentArray[16],
                        ['==', ['get', 'department_id'], 18],
                        departmentArray[17],
                        'transparent'
                    ]
                },
                filter: ['==', ['get', 'floor_id'], floor],
            }, 'plansGroupByDepartment-borders');

            map.addLayer({
                id: 'plans-labels',
                type: 'symbol',
                source: 'labelsOfPlans',
                'minzoom': 20.5,
                'paint': {
                    'text-color': '#fff',
                    'text-halo-color': '#fff'
                },
                filter: ['==', ['get', 'floor_id'], floor],
                layout: {
                    'text-field': ['get', 'name'],
                    "text-letter-spacing": 0.05,
                    'text-transform': 'uppercase',
                    'text-rotate': ['get', 'bearing'],
                    'text-font': ['Montserrat SemiBold', 'Arial Unicode MS Bold'],
                    'text-size': 14,
                    'text-allow-overlap': true,
                    'icon-allow-overlap': true,
                    "symbol-sort-key": ["to-number", ["get", "priority"]],
                }
            });

            if (map.hasImage('icon-enter')) map.removeImage('icon-enter');

            // let customExitIcon = new Image(45, 25);
            //     customExitIcon.onload = () => map.addImage('icon-enter', customExitIcon)
            //     customExitIcon.src = iconEnter;


            mapLoadRasterizedImage(map, 'icon-enter', iconEnter)
                .then(() => {
                    map.addLayer({
                        id: 'plansGroupedByType-icons-enter',
                        source: 'labelsGroupedByType',
                        type: 'symbol',
                        filter: ['all', ['==', ['get', 'floor_id'], floor], ['==', ['get', 'type_id'], 55]],
                        'minzoom': 18.8,
                        'layout': {
                            // 'icon-size': 10,
                            'icon-size': ['interpolate', ['linear'], ['zoom'], 18, 0.1, 22, 0.8],
                            'icon-image': 'icon-enter'
                        }
                    });
                })
   

            symbols.forEach((icon, index) => {
                if (map.hasImage(icon.id)) map.removeImage(icon.id);

                let customIcon = new Image(60, 60);
                    customIcon.onload = () => map.addImage(icon.id, customIcon)
                    customIcon.src = icon.imageUrl;

                if (index === symbols.length - 1) {
                    map.addLayer({
                        id: 'plansGroupedByType-icons',
                        source: 'labelsGroupedByType',
                        type: 'symbol',
                        'minzoom': 18.8,
                        'layout': {
                            'icon-size': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                18.8, 0.2,
                                19.5, 0.3,
                                20.0, 0.4,
                                20.5, 0.5,
                                21, 0.6,
                                21.5, 0.9,
                                21.8, 1
                            ],
                            'icon-allow-overlap': true,
                            'icon-image': ['get', 'image']
                        },
                        filter: ['==', ['get', 'floor_id'], floor]
                    });
                }
            }, 'plansGroupByDepartment-labels');

            map.addLayer({
                id: 'plansGroupedByType-labels',
                type: 'symbol',
                source: 'labelsGroupedByType',
                'minzoom': 18.8,
                'paint': {
                    'text-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 'rgba(27, 32, 61, 1)',
                        20.4, 'rgba(27, 32, 61, 1)',
                        20.5, 'rgba(27, 32, 61, 0.4)'
                    ],
                    'text-halo-width': 1,
                    'text-halo-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 'white',
                        20.4, 'white',
                        20.5, 'rgba(255, 255, 255, 0.4)'
                    ]
                },
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]], ['!', ['in', ['get', 'type_id'], ['literal', notMedicacGroupTypes]]], ['==', ['get', 'bearing'], 0]],
                layout: {
                    'text-allow-overlap': false,
                    'text-transform': 'uppercase',
                    'text-line-height': 1.25,
                    'icon-size': 0.1,
                    'icon-allow-overlap': true,
                    "symbol-sort-key": ["to-number", ["get", "priority"]],
                    'text-field': ['get', 'type_name'],
                    "text-letter-spacing": 0.1,
                    // 'text-max-width': ['-', ['get', 'width'], 7],
                    'text-max-width': ['/', ['get', 'width'], 2],
                    'text-font': ['Montserrat Medium', 'Arial Unicode MS Bold'],
                    'text-variable-anchor': ['center'],
                    // 'text-offset': ['literal', [0, -0.6]],
                    // 'icon-image': 'icon-circle',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 10,
                        19.0, 12,
                        19.5, 14,
                        20.0, 16,
                        20.5, 18,
                        21, 20,
                        21.5, 22
                    ]
                }
            });

            map.addLayer({
                id: 'plansGroupedByType-labels-rotated',
                type: 'symbol',
                source: 'labelsGroupedByType',
                'minzoom': 18.8,
                'paint': {
                    'text-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 'rgba(27, 32, 61, 1)',
                        20.4, 'rgba(27, 32, 61, 1)',
                        20.5, 'rgba(27, 32, 61, 0.4)'
                    ],
                    'text-halo-width': 1,
                    'text-halo-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 'white',
                        20.4, 'white',
                        20.5, 'rgba(255, 255, 255, 0.4)'
                    ]
                },
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['in', ['get', 'type_id'], ['literal', notMedicacGroupTypes]]], ['!', ['==', ['get', 'bearing'], 0]]],
                layout: {
                    'text-allow-overlap': false,
                    'text-transform': 'uppercase',
                    'text-line-height': 1.25,
                    'icon-size': 0.1,
                    'icon-allow-overlap': true,
                    "symbol-sort-key": ["to-number", ["get", "priority"]],
                    'text-field': ['get', 'type_name'],
                    "text-letter-spacing": 0.06,
                    'text-rotate': ['get', 'bearing'],
                    'text-max-width': ['/', ['get', 'width'], 2],
                    'text-font': ['Montserrat Medium', 'Arial Unicode MS Bold'],
                    'text-variable-anchor': ['center'],
                    // 'icon-image': 'icon-circle',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18.8, 10,
                        19.0, 12,
                        19.5, 14,
                        20.0, 16,
                        20.5, 18,
                        21, 20,
                        21.5, 22
                    ]
                }
            });

            map.addLayer({
                id: 'plansGroupedByType-borders-highlighted',
                type: 'line',
                source: 'plansGroupByType',
                'minzoom': 18.8,
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'paint': {
                    'line-color': [
                        'case',
                        ['==', ['get', 'type_id'], 22], 'transparent',
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], notMedicalCategory]],
                        '#ED9082',
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 1]],
                        departmentDarkArray[0],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 2]],
                        departmentDarkArray[1],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 3]],
                        departmentDarkArray[2],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 4]],
                        departmentDarkArray[3],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 5]],
                        departmentDarkArray[4],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 6]],
                        departmentDarkArray[5],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 7]],
                        departmentDarkArray[6],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 8]],
                        departmentDarkArray[7],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 9]],
                        departmentDarkArray[8],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 10]],
                        departmentDarkArray[9],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 11]],
                        departmentDarkArray[10],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 12]],
                        departmentDarkArray[11],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 13]],
                        departmentDarkArray[12],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 14]],
                        departmentDarkArray[13],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 15]],
                        departmentDarkArray[14],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 16]],
                        departmentDarkArray[15],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 17]],
                        departmentDarkArray[16],
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 18]],
                        departmentDarkArray[17],
                        'transparent'
                    ],
                    'line-width': 3
                }
            }, 'plansGroupedByType-icons');

            map.addLayer({
                id: 'plansGroupedByType-borders',
                type: 'line',
                source: 'plansGroupByType',
                'minzoom': 18.8,
                'maxzoom': 20.5,
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'paint': {
                    'line-color': [
                        'case',
                        // ['all', ['boolean', ['feature-state', 'hover'], false], ['==', ['get', 'category_id'], notMedicalCategory]],
                        // '#ED9082',
                        ['==', ['get', 'type_id'], 22], 'transparent',
                        ['all', ['==', ['get', 'department_id'], 1], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[0],
                        ['all', ['==', ['get', 'department_id'], 2], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[1],
                        ['all', ['==', ['get', 'department_id'], 3], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[2],
                        ['all', ['==', ['get', 'department_id'], 4], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[3],
                        ['all', ['==', ['get', 'department_id'], 5], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[4],
                        ['all', ['==', ['get', 'department_id'], 6], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[5],
                        ['all', ['==', ['get', 'department_id'], 7], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[6],
                        ['all', ['==', ['get', 'department_id'], 8], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[7],
                        ['all', ['==', ['get', 'department_id'], 9], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[8],
                        ['all', ['==', ['get', 'department_id'], 10], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[9],
                        ['all', ['==', ['get', 'department_id'], 11], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[10],
                        ['all', ['==', ['get', 'department_id'], 12], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[11],
                        ['all', ['==', ['get', 'department_id'], 13], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[12],
                        ['all', ['==', ['get', 'department_id'], 14], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[13],
                        ['all', ['==', ['get', 'department_id'], 15], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[14],
                        ['all', ['==', ['get', 'department_id'], 16], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[15],
                        ['all', ['==', ['get', 'department_id'], 17], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[16],
                        ['all', ['==', ['get', 'department_id'], 18], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[17],
                        ['==', ['get', 'category_id'], notMedicalCategory], '#ED9082',
                        'transparent'
                    ],
                    'line-width': [
                        'case',
                        ['all', ['in', ['get', 'type_id'], ['literal', planType]], ['==', ['get', 'floor_id'], floor]],
                        2,
                        1
                    ]
                }
            }, 'plansGroupedByType-borders-highlighted');

            map.addLayer({
                id: 'plansGroupedByType-areas',
                type: 'fill',
                'minzoom': 18.8,
                'maxzoom': 20.5,
                source: 'plansGroupByType',
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'paint': {
                    'fill-color': [
                        'case',
                        ['==', ['get', 'type_id'], 22],
                        '#FFF4F2',
                        ['==', ['get', 'category_id'], notMedicalCategory],
                        '#FFE4DE',
                        ['all', ['==', ['get', 'department_id'], 1], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[0],
                        ['all', ['==', ['get', 'department_id'], 2], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[1],
                        ['all', ['==', ['get', 'department_id'], 3], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[2],
                        ['all', ['==', ['get', 'department_id'], 4], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[3],
                        ['all', ['==', ['get', 'department_id'], 5], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[4],
                        ['all', ['==', ['get', 'department_id'], 6], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[5],
                        ['all', ['==', ['get', 'department_id'], 7], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[6],
                        ['all', ['==', ['get', 'department_id'], 8], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[7],
                        ['all', ['==', ['get', 'department_id'], 9], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[8],
                        ['all', ['==', ['get', 'department_id'], 10], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[9],
                        ['all', ['==', ['get', 'department_id'], 11], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[10],
                        ['all', ['==', ['get', 'department_id'], 12], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[11],
                        ['all', ['==', ['get', 'department_id'], 13], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[12],
                        ['all', ['==', ['get', 'department_id'], 14], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[13],
                        ['all', ['==', ['get', 'department_id'], 15], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[14],
                        ['all', ['==', ['get', 'department_id'], 16], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[15],
                        ['all', ['==', ['get', 'department_id'], 17], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[16],
                        ['all', ['==', ['get', 'department_id'], 18], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[17],
                        'transparent'
                    ],
                    'fill-opacity': [
                        'case',
                        ['all', ['boolean', ['feature-state', 'hover'], false]],
                        0.7,
                        1,
                    ]
                }
            }, 'plansGroupedByType-borders');

            map.addLayer({
                'id': 'plans-borders-highlighted',
                'type': 'line',
                'source': 'rooms',
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'layout': {},
                'minzoom': 18.8,
                'paint': {
                    'line-color': [
                        'case',
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], notMedicalCategory]],
                        '#ED9082',
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 1]],
                        departmentDarkArray[0],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 2]],
                        departmentDarkArray[1],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 3]],
                        departmentDarkArray[2],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 4]],
                        departmentDarkArray[3],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 5]],
                        departmentDarkArray[4],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 6]],
                        departmentDarkArray[5],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 7]],
                        departmentDarkArray[6],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 8]],
                        departmentDarkArray[7],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 9]],
                        departmentDarkArray[8],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 10]],
                        departmentDarkArray[9],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 11]],
                        departmentDarkArray[10],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 12]],
                        departmentDarkArray[11],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 13]],
                        departmentDarkArray[12],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 14]],
                        departmentDarkArray[13],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 15]],
                        departmentDarkArray[14],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 16]],
                        departmentDarkArray[15],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 17]],
                        departmentDarkArray[16],
                        ['all', ['==', ['get', 'id'], roomId], ['==', ['get', 'category_id'], medicalCategory], ['==', ['get', 'department_id'], 18]],
                        departmentDarkArray[17],
                        'transparent'
                    ],
                    'line-width': 3
                }
            }, 'plansGroupedByType-borders-highlighted');

            map.addLayer({
                'id': 'plans-borders',
                'type': 'line',
                'source': 'rooms',
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'layout': {},
                'minzoom': 20.5,
                'paint': {
                    'line-width': 0,
                    'line-color': [
                        'case',
                        ['==', ['get', 'category_id'], notMedicalCategory],
                        '#FD8B7B',
                        ['all', ['==', ['get', 'department_id'], 1], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[0],
                        ['all', ['==', ['get', 'department_id'], 2], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[1],
                        ['all', ['==', ['get', 'department_id'], 3], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[2],
                        ['all', ['==', ['get', 'department_id'], 4], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[3],
                        ['all', ['==', ['get', 'department_id'], 5], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[4],
                        ['all', ['==', ['get', 'department_id'], 6], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[5],
                        ['all', ['==', ['get', 'department_id'], 7], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[6],
                        ['all', ['==', ['get', 'department_id'], 8], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[7],
                        ['all', ['==', ['get', 'department_id'], 9], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[8],
                        ['all', ['==', ['get', 'department_id'], 10], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[9],
                        ['all', ['==', ['get', 'department_id'], 11], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[10],
                        ['all', ['==', ['get', 'department_id'], 12], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[11],
                        ['all', ['==', ['get', 'department_id'], 13], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[12],
                        ['all', ['==', ['get', 'department_id'], 14], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[13],
                        ['all', ['==', ['get', 'department_id'], 15], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[14],
                        ['all', ['==', ['get', 'department_id'], 16], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[15],
                        ['all', ['==', ['get', 'department_id'], 17], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[16],
                        ['all', ['==', ['get', 'department_id'], 18], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[17],
                        'transparent'
                    ]
                }
            }, 'plans-borders-highlighted');

            map.addLayer({
                'id': 'plans-walls',
                'type': 'line',
                'source': 'plans-walls',
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'layout': {},
                'minzoom': 20.5,
                'paint': {
                    'line-width': {
                        stops: [
                          [0, 0],
                          [25, 20],
                        ],
                        base: 2,
                     },
                    'line-color': [
                        'case',
                        ['==', ['get', 'category_id'], notMedicalCategory],
                        '#FD8B7B',
                        ['all', ['==', ['get', 'department_id'], 1], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[0],
                        ['all', ['==', ['get', 'department_id'], 2], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[1],
                        ['all', ['==', ['get', 'department_id'], 3], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[2],
                        ['all', ['==', ['get', 'department_id'], 4], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[3],
                        ['all', ['==', ['get', 'department_id'], 5], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[4],
                        ['all', ['==', ['get', 'department_id'], 6], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[5],
                        ['all', ['==', ['get', 'department_id'], 7], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[6],
                        ['all', ['==', ['get', 'department_id'], 8], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[7],
                        ['all', ['==', ['get', 'department_id'], 9], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[8],
                        ['all', ['==', ['get', 'department_id'], 10], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[9],
                        ['all', ['==', ['get', 'department_id'], 11], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[10],
                        ['all', ['==', ['get', 'department_id'], 12], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[11],
                        ['all', ['==', ['get', 'department_id'], 13], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[12],
                        ['all', ['==', ['get', 'department_id'], 14], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[13],
                        ['all', ['==', ['get', 'department_id'], 15], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[14],
                        ['all', ['==', ['get', 'department_id'], 16], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[15],
                        ['all', ['==', ['get', 'department_id'], 17], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[16],
                        ['all', ['==', ['get', 'department_id'], 18], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentDarkArray[17],
                        'transparent'
                    ]
                }
            }, 'plans-borders-highlighted');

            map.addLayer({
                id: 'plans-areas',
                type: 'fill',
                'minzoom': 20.5,
                source: 'rooms',
                filter: ['all', ['==', ['get', 'floor_id'], floor], ['!', ['==', ['get', 'type_id'], 55]]],
                'paint': {
                    // 'fill-color': [
                    //     'case',
                    //     ['all', ['boolean', ['feature-state', 'hover'], false], ['==', ['get', 'category_id'], notMedicalCategory]],
                    //     '#ED9082',
                    //     ['all', ['boolean', ['feature-state', 'hover'], false], ['==', ['get', 'category_id'], medicalCategory]],
                    //     '#007BFE',
                    //     ['==', ['get', 'category_id'], notMedicalCategory],
                    //     '#FADFDB',
                    //     '#75ACFF'
                    // ],
                    'fill-color': [
                        'case',
                        ['==', ['get', 'type_id'], 22],
                        '#FFF4F2',
                        ['==', ['get', 'category_id'], notMedicalCategory],
                        '#FFE4DE',
                        ['all', ['==', ['get', 'department_id'], 1], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[0],
                        ['all', ['==', ['get', 'department_id'], 2], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[1],
                        ['all', ['==', ['get', 'department_id'], 3], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[2],
                        ['all', ['==', ['get', 'department_id'], 4], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[3],
                        ['all', ['==', ['get', 'department_id'], 5], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[4],
                        ['all', ['==', ['get', 'department_id'], 6], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[5],
                        ['all', ['==', ['get', 'department_id'], 7], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[6],
                        ['all', ['==', ['get', 'department_id'], 8], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[7],
                        ['all', ['==', ['get', 'department_id'], 9], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[8],
                        ['all', ['==', ['get', 'department_id'], 10], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[9],
                        ['all', ['==', ['get', 'department_id'], 11], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[10],
                        ['all', ['==', ['get', 'department_id'], 12], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[11],
                        ['all', ['==', ['get', 'department_id'], 13], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[12],
                        ['all', ['==', ['get', 'department_id'], 14], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[13],
                        ['all', ['==', ['get', 'department_id'], 15], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[14],
                        ['all', ['==', ['get', 'department_id'], 16], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[15],
                        ['all', ['==', ['get', 'department_id'], 17], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[16],
                        ['all', ['==', ['get', 'department_id'], 18], ['==', ['get', 'category_id'], medicalCategory]],
                        departmentArray[17],
                        'transparent'
                    ],
                    'fill-opacity': [
                        'case',
                        ['all', ['boolean', ['feature-state', 'hover'], false], ['==', ['get', 'is_active'], true]],
                        0.7,
                        1
                    ]
                }
            }, 'plans-labels');

            addHoverHandlers(map, 'plans-areas', 'rooms');
            addHoverHandlers(map, 'plansGroupedByType-areas', 'plansGroupByType', 'type_name');
            addHoverHandlers(map, 'plansGroupByDepartment-areas', 'plansGroupByDepartment', 'department_name');

            map.on('render', function() {
                if (!map.loaded() || mapRendered) return;

                let myFeatures = map.querySourceFeatures('rooms', {
                    filter: ['==', ['get', 'floor_id'], floor]
                }).map(feature => {
                    return feature.properties.type_id});

                setPlanTypes([...new Set(myFeatures)]);

                mapRendered = true;
            });

            // map.on('zoom', function() {
            //     console.log(map.getZoom())
            // });
        }

        map.once('style.load', () => initFloor(floor, planType, roomId));

        map.once('load', () => {
            setMap(map);
            initFloorRef.current = initFloor;
        });

        return () => {
            setMap(null);
            map.remove();
        }
    }, [layerParams, plans]); //eslint-disable-line

    const addHoverHandlers = (map, layerName, sourceName) => {
        let hoveredPolygonId = null;

        // map.on('click', layerName, (e) => {
        //     console.log(e.features[0])
        // });

        map.on('mousemove', layerName, (e) => {
            if (e.features.length > 0) {
                if (hoveredPolygonId !== null) {
                    map.setFeatureState(
                        { source: sourceName, id: hoveredPolygonId },
                        { hover: false }
                    );
                }

                hoveredPolygonId = e.features[0].id;

                map.setFeatureState(
                    { source: sourceName, id: hoveredPolygonId },
                    { hover: true }
                );
            }
        });

        map.on('mouseleave', layerName, () => {
            if (hoveredPolygonId !== null) {
                map.setFeatureState(
                    { source: sourceName, id: hoveredPolygonId },
                    { hover: false }
                );
            }

            hoveredPolygonId = null;
        });
    }

    const onFloorChange = (floor) => {
        initFloorRef.current?.(floor);
        setFloor(floor);

        // map.fitBounds(layerParams.extent, {
        //     padding: { top: 20, bottom:20, left: 20, right: 20}
        // });
    }

    const handleOnSelect = (item) => {
        if (item.bound.length > 1) {
            initFloorRef.current?.(item.properties.floor_id, [item.properties.type_id]);
            moveToBoundedArea(item.bound);
        } else {
            initFloorRef.current?.(item.properties.floor_id, null, item.id);
            moveToBoundedArea(item.geometry.coordinates[0][0]);
        }

        setPlanType([item.properties.type_id]);
        setFloor(item.properties.floor_id);
    }

    const moveToBoundedArea = (coordinates) => {
        const line = turf.lineString(coordinates);
        const bbox = turf.bbox(line);

        map.fitBounds(bbox, {
            padding: { top: 20, bottom:20, left: 20, right: 20}
        });
    }

    const formatResult = (item) => {
        if (!item) return;

        let rooms = (item.rooms.length > 0) ? item.rooms.join(', ') : '';

        return (
            <>
                <span className="autocomplete-floor">{item.properties.floor_name}</span>
                <span className="autocomplete-name">
                    { item.properties.type_name }
                    { rooms && <span className="autocomplete-rooms"> ({ rooms })</span> }
                </span>
            </>
        )
    }

    return (
        <StyledMap>
            {hasError && <div className="error">Some error has occured</div>}
            {!hasError && floors && plans && (
                <>
                    <main className="map-wrapper">
                        <div id="map" ref={mapRef} />
                        <Controls map={map} />
                        <Header floor={floor} />
                    </main>
                    <aside>
                        { planTypes && (
                            <FiltersPanel map={map}
                                allPlanTypes={listOfPlanTypes}
                                planTypesOnMap={planTypes}
                                rootCategories={rootCategories}
                                items={plans.features}
                                floor={floor}
                                setFloor={onFloorChange}
                            >
                                <ReactSearchAutocomplete
                                    items={plans.features}
                                    onSelect={handleOnSelect}
                                    showClear={true}
                                    title='MEDICAL CENTER'
                                    autoFocus
                                    formatResult={formatResult}
                                  />
                            </FiltersPanel>
                        )}
                    </aside>
                    <nav className="navigation-section">
                        <PaginationControl
                            page={floor}
                            between={2}
                            label="FL"
                            total={floors.length}
                            limit={1}
                            changePage={(floor) => {
                              onFloorChange(floor)
                            }}
                            ellipsis={0}
                        />
                  </nav>
                  <Contacts />
                </>
            )}
            { isLoading && (
                <div className="loader">
                    <div className="loader-ripple">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) }

        </StyledMap>
    );
}

const StyledMap = styled.div`
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: rgb(229, 236, 252);
    .loader {
        background-color: linear-gradient(180deg, #E5ECFC 0%, #D8E4FA 24.71%, #E3ECFA 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1001;
        font-size: 50px;
        color: white;
    }

    aside {
        position: absolute;
        left: 30px;
        top: 30px;
        border: 1px solid #E6E6E6;
        padding-top: 20px;
        max-width: 360px;
        background: #fff;
        border-radius: 20px;
        box-shadow: rgba(114, 119, 143, 0.2) 0px 4px 20px;
    }

    .loader-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
            position: absolute;
            border: 4px solid #1668F5;
            opacity: 1;
            border-radius: 50%;
            animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

            &:nth-child(2) {
                animation-delay: -0.5s;
            }
        }
    }

    @keyframes loader-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 0;
        }
        4.9% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 0;
        }
        5% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }

    .error {
        color: #ED9082;
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
        text-align: center;
    }

    .autocomplete-floor {
        color: #83899D;
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        font-weight: 500;
    }

    .autocomplete-name {
        color: #1B203D;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        display: block;
    }

    .autocomplete-rooms {
        font-weight: 400;
        font-size: 12px;
        text-transform: lowercase;
    }

    .navigation-section {
        transform: translate(0, -50%);
        position: absolute;
        top: 50%;
        right: 30px;
        text-align: center;
        z-index: 1;
        flex-grow: 1;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-shadow: rgba(114, 119, 143, 0.2) 0px 4px 20px;
    }

    .map-wrapper, #map {
        position: relative;
        width: 100%;
        height: 100%;
        flex-grow: 1;
    }
`;
export default Map;
