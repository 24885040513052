import { point, lineString } from '@turf/helpers';
import bearing from '@turf/bearing';
import centerOfMass from '@turf/center-of-mass';
import centroid from '@turf/centroid';
import transformRotate from '@turf/transform-rotate';
import transformTranslate from '@turf/transform-translate';
import transformScale from '@turf/transform-scale';
import rhumbBearing from '@turf/rhumb-bearing';
import rhumbDistance from '@turf/rhumb-distance';
import along from '@turf/along';

export const getPointFromCenter = (feature, bearingFromCenter, distance) => {
  const center = centerOfMass(feature);
  const line = lineString([center.geometry.coordinates, [0, 0]]);
  const center2 = point([0, 0]);
  const b = bearing(center, center2);
  const angle = bearingFromCenter - b;
  const rotatedLine = transformRotate(line, angle, { pivot: center });

  if (feature.properties.bearing) {
    const angle = feature.properties.bearing - bearingFromCenter;
    const rotatedLine2 = transformRotate(rotatedLine, angle, { pivot: center });
    return along(rotatedLine2, distance).geometry.coordinates;
  } else {
    return along(rotatedLine, distance).geometry.coordinates;
  }
}

export const moveFeature = (feature, moveTo) => {
  const pointToMove = point(moveTo);
  const center = centroid(feature);
  const distanceToPoint = rhumbDistance(center, pointToMove);
  const bearingToPoint = rhumbBearing(center, pointToMove);
  const movedFeature = transformTranslate(feature, distanceToPoint, bearingToPoint);
  feature.geometry.coordinates = movedFeature.geometry.coordinates;
  return feature;
}

export const scaleFeature = (feature) => {
  if (feature.properties.scale) {
    const center = centroid(feature);
    const scaledFeature = transformScale(feature, feature.properties.scale, { origin: center });
    feature.geometry.coordinates = scaledFeature.geometry.coordinates;
  }
  return feature;
}

export const rotateFeature = (feature, angle) => {
  const center = centroid(feature);
  const rotatedFeature = transformRotate(feature, angle, { pivot: center });
  feature.geometry.coordinates = rotatedFeature.geometry.coordinates;
  return feature;
}

export const getLineFirstCoord = line => {
  if (line.geometry.type === 'MultiLineString') return line.geometry.coordinates[0][0];
  return line.geometry.coordinates[0];
};

export const getLineLastCoord = line => {
  if (line.geometry.type === 'MultiLineString') {
    const lineCount = line.geometry.coordinates.length;
    const lastLineCount = line.geometry.coordinates[lineCount - 1].length;
    return line.geometry.coordinates[lineCount - 1][lastLineCount - 1];
  }
  const lineCount = line.geometry.coordinates.length;
  return line.geometry.coordinates[lineCount - 1];
};
