import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconArrowBottom } from '../../../img/icon-arrow-bottom.svg';
import { ReactComponent as IconArrowTop } from '../../../img/icon-arrow-top.svg';

export const PaginationControl = ({
  page = 1,
  between = 3,
  label,
  total,
  limit,
  changePage = page => console.log(page),
  next = true,
  last = false,
  ellipsis = 0,
  ...paginationProps
}) => {
  const total_pages = Math.ceil(total / limit)
  between = between < 1 ? 1 : between
  page = (page < 1 ? 1 : page > total_pages ? total_pages : page)
  ellipsis = ellipsis < 1 ? 0 : ellipsis + 2 >= between ? between - 2 : ellipsis

  let positions = Array.from({ length: total_pages }, (_, i) => i)

  const qtd_pages = (between * 2) + 1
  const range = (
    total_pages <= qtd_pages
      ? positions
      : page - 1 <= between
        ? positions.slice(0, qtd_pages - (ellipsis > 0 ? ellipsis + 1 : 0))
        : page + between >= total_pages
          ? positions.slice(total_pages - qtd_pages + (ellipsis > 0 ? ellipsis + 1 : 0), total_pages)
          : positions.slice((page - 1) - (between - (ellipsis > 0 ? ellipsis + 1 : 0)), page + (between - (ellipsis > 0 ? ellipsis + 1 : 0)))
  )

  return (
    total !== null && total > 0
      ? <StyledPaginationControl {...paginationProps}>
            <div className="title">{ label }</div>
            <div className="container">
            {
              last
              && <button type="button" className="item first"
                onClick={() => page > 1 ? changePage(1) : {}}
                disabled={page <= 1} />
            }
            {
              next
              && <button type="button" className="item prev"
                onClick={() => page > 1 ? changePage(page - 1) : {}}
                disabled={page <= 1}><IconArrowTop /></button>
            }
            {
              total_pages > (between * 2) + 1 && ellipsis > 0
              && positions.slice(0, page - 1 <= between ? 0 : ellipsis).map(value => {
                return <div className="item" key={value}
                  onClick={() => value !== page - 1 ? changePage(value + 1) : {}}>
                  {value + 1}
                </div>
              })
            }
            {
              total_pages > (between * 2) + 1 && ellipsis > 0 && page - 1 > between
              && <div className="item ellipsis" disabled>...</div>
            }
            {range.map(value => {
              return <div className={`item ${value === page - 1 ? 'active' : ''}`}
                key={value}
                onClick={() => value !== page - 1 ? changePage(value + 1) : {}}>
                {value + 1}
              </div>
            })}
            {
              total_pages > (between * 2) + 1 && ellipsis > 0 && page < total_pages - between
              && <div className="item ellipsis" disabled>...</div>
            }
            {
              total_pages > (between * 2) + 1 && ellipsis > 0
              && positions.slice(page >= total_pages - between ? total_pages : total_pages - ellipsis, total_pages).map(value => {
                return <div className="item" key={value}
                  onClick={() => value !== page - 1 ? changePage(value + 1) : {}}>
                  {value + 1}
                </div>
              })
            }
            {
              next
              && <button type="button" className="item next"
                onClick={() => page < total_pages ? changePage(page + 1) : {}}
                disabled={page >= total_pages}><IconArrowBottom /></button>
            }
            {
              last
              && <button type="button" className="item last"
                onClick={() => page < total_pages ? changePage(total_pages) : {}}
                disabled={page >= total_pages} />
            }
            </div>
      </StyledPaginationControl>
      : <></>
  )
}

const StyledPaginationControl = styled.div`
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #E6E6E6;
    background-color: #fff;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    user-select: none;

  .title {
    font-weight: 700;
    font-size: 15px;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .item {
    background: none;
    border: none;
    outline: inherit;
    line-height: 1;
    font-size: 14px;
    justify-content: center;
    cursor: pointer;
    color: #1B203D;
    padding: 0;
    margin: 10px 0 15px;

    &.prev {
        margin-bottom: 5px;
        margin-top: 0;
    }

    &.next {
        margin-top: 5px;
        margin-bottom: 0;
    }

    &.prev, &.next {
        > svg {
            fill: #1B203D;
        }

        &:disabled {
            > svg {
                fill: #CCCCCC;
            }
        }
    }

    &.ellipsis {
        opacity: 0.7;
    }

    .description {
      font-weight: 500;
      font-size: 15px;
      text-align: center;
    }

    &.active:not(.ellipsis) {
        color: #1668F5;
        font-size: 16px;
        font-weight: 700;
        border-bottom: 1px solid #1668F5;
    }

    &:hover:not(.ellipsis):not(.active) {
        color: #1668F5;
        font-weight: 700;
    }
  }
`;
