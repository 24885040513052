const defaultTheme = {
    height: '52px',
    dropdownIndent: '25px',
    width: '321px',
    boxShadow: '0px 0px 0px 1px #CCCCCC',
    borderRadius: '10px',
    maxHeight: '300px',
    backgroundColor: '#fff',
    hoverBackgroundColor: 'rgba(117, 172, 255, 0.3)',
    color: '#212121',
    fontSize: '15px',
    fontFamily: 'Montserrat',
    iconColor: '#CCCCCC',
    lineColor: 'rgb(232, 234, 237)',
    placeholderColor: '#1B203D',
    zIndex: 3,
    clearIconMargin: '3px 14px 0 0',
    searchIconMargin: '0'
}

const defaultFuseOptions = {
    shouldSort: true,
    matchAllTokens: true,
    findAllMatches: true,
    includeScore: true,
    threshold: 0,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['properties.type_name', 'properties.name']
}

export { defaultTheme, defaultFuseOptions }
