import { ChangeEventHandler, FocusEvent, FocusEventHandler, useRef } from 'react'
import styled from 'styled-components'
import { ClearIcon } from './ClearIcon'
import { SearchIcon } from './SearchIcon'

export default function SearchInput({
  searchString,
  setSearchString,
  setHighlightedItem,
  eraseResults,
  autoFocus,
  onFocus,
  onClear,
  placeholder,
  showIcon = true,
  showClear = true,
  maxLength
}) {
  const ref = useRef(null)

  let manualFocus = true

  const setFocus = () => {
    manualFocus = false
    ref?.current && ref.current.focus()
    manualFocus = true
  }

  const handleOnFocus = (event) => {
    manualFocus && onFocus(event)
  }

  const maxLengthProperty = maxLength ? { maxLength } : {}

  return (
    <StyledSearchInput>
      <input
        type="text"
        ref={ref}
        spellCheck={false}
        value={searchString}
        onChange={setSearchString}
        onFocus={handleOnFocus}
        autoFocus={autoFocus}
        placeholder='Search'
        onBlur={() => eraseResults()}
        onKeyDown={(event) => setHighlightedItem({ event })}
        data-test="search-input"
        {...maxLengthProperty}
      />
      {!Boolean(searchString) && <SearchIcon showIcon={showIcon} />}
      {Boolean(searchString) && (
        <ClearIcon
          showClear={showClear}
          setSearchString={setSearchString}
          searchString={searchString}
          onClear={onClear}
          setFocus={setFocus}
        />
      )}
    </StyledSearchInput>
  )
}

const StyledSearchInput = styled.div`
    min-height: 48px;
    width: 100%;
    border-radius: ${(props) => props.theme.borderRadius};

    display: flex;
    align-items: center;

    > input {
        width: 100%;

        padding: 0 0 0 15px;

        border: none;
        outline: none;

        background-color: rgba(0, 0, 0, 0);
        font-size: inherit;
        font-family: inherit;

        color: ${(props) => props.theme.color};

        ::placeholder {
            color: ${(props) => props.theme.placeholderColor};
            letter-spacing: 0.3px;
            opacity: 1;

            ::-webkit-input-placeholder {
                color: ${(props) => props.theme.placeholderColor};
            }

            :-moz-placeholder {
                color: ${(props) => props.theme.placeholderColor};
            }

            ::-moz-placeholder {
                color: ${(props) => props.theme.placeholderColor};
            }

            :-ms-input-placeholder {
                color: ${(props) => props.theme.placeholderColor};
            }
        }
    }
`
