import _ from 'underscore';

export function debounce(func, wait, immediate) {
  let timeout;

  return function () {
    const context = this
    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    if (immediate && !timeout) func.apply(context, args)

    timeout && clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const loadImage = async(path) => {
    return new Promise( (resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve({path, status: 'ok'})
        image.onerror = () => reject({ path, status: 'error'})
        image.src = path;
  });
};

export const classNames = (classes) => {
    return Object.entries(classes)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
}

export const getFeaturesOnMap = (filtersList, items, floor) => {
    let uniqueResults = [];

    filtersList.forEach(id => {
        items.filter(elem => elem.properties.type_id == id && elem.properties.floor_id == floor ).forEach(feature => {
            let isFeatureUniq = _.find(uniqueResults, function(element) { return (element.properties.id == feature.properties.id) });
            if (!isFeatureUniq) {
                uniqueResults = [...uniqueResults, feature];
            }
        })
    });

    return uniqueResults;
};

export const mapLoadImage = async (map, id, source, replace = false) => {
  if (map.hasImage(id) && !replace) return;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (map.hasImage(id)) map.removeImage(id);
      resolve(map.addImage(id, img));
    };
    img.onerror = e => reject(e);
    img.src = source;
  });
};

export const mapLoadRasterizedImage = async (map, id, source, replace = false) => {
  if (map.hasImage(id) && !replace) return;
  return new Promise(async (resolve, reject) => {
    const img = new Image();
    const imageUrl = await rasterizeSvg(source);
    img.onload = () => {
      if (map.hasImage(id)) map.removeImage(id);
      resolve(map.addImage(id, img));
    };
    img.onerror = e => reject(e);
    img.src = imageUrl;
  });
};

export const rasterizeSvg = async (source, finalWidth = 64) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const originalWidth = img.width;
      const originalHeight = img.height;

      img.width = finalWidth;
      img.height = (originalHeight / originalWidth) * finalWidth;

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0, img.width, img.height);
      resolve(canvas.toDataURL());
      canvas.remove();
    };
    img.onerror = e => reject(e);
    img.src = source;
  });
};
