import React from 'react';
import Map from './../components/Map';

function MapPage() {
  return (
    <Map />
  );
}

export default MapPage;
