import { MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'

export default function Results ({
  results = [],
  onClick,
  setSearchString,
  showIcon,
  maxResults,
  resultStringKeyName = 'type_name',
  resultStringKeyNameForRooms = 'name',
  highlightedItem,
  setHighlightedItem,
  formatResult,
  showNoResultsFlag = true,
  showNoResultsText = 'No results'
}) {
  const formatResultWithKey = formatResult
    ? formatResult
    : (item) => item.properties[resultStringKeyName]

  const handleClick = (result) => {
    let resultStringName = (result.bound.length > 1) ? result.properties[resultStringKeyName] :
        (result.properties[resultStringKeyNameForRooms]) ? result.properties[resultStringKeyNameForRooms] : result.properties[resultStringKeyName];

    onClick(result);
    setSearchString(resultStringName);
  }

  const handleMouseDown = ({
    event,
    result
  }) => {
    if (event.button === 0) {
        event.preventDefault()
        handleClick(result)
    }
  }

  if (showNoResultsFlag) {
    return (
      <ResultsWrapper>
        <li data-test="no-results-message">
          <div className="ellipsis">{showNoResultsText}</div>
        </li>
      </ResultsWrapper>
    )
  }

  if (results.length <= 0 && !showNoResultsFlag) {
    return null
  }

  return (
    <ResultsWrapper>
      {results.slice(0, maxResults).map((result, index) => (
        <li
          className={highlightedItem === index ? 'selected' : ''}
          onMouseEnter={() => setHighlightedItem({ index })}
          data-test="result"
          key={`rsa-result-${result.id}`}
          onMouseDown={(event) => handleMouseDown({ event, result })}
          onClick={() => handleClick(result)}
        >
          <div className="ellipsis" title={result.properties[resultStringKeyName]}>
            {formatResultWithKey(result)}
          </div>
        </li>
      ))}
    </ResultsWrapper>
  )
}

const ResultsWrapper = ({ children }) => {
  return (
    <StyledResults>
      <ul>{children}</ul>
    </StyledResults>
  )
}

const StyledResults = styled.div`
    > ul {
        list-style-type: none;
        margin: 0;
        padding: 10px 0px 10px 0;
        max-height: ${(props) => props.theme.maxHeight};
        overflow: auto;

        > li {
            display: flex;
            border-radius: 5px;
            align-items: center;
            padding: 10px 0;

            > div {
                margin-left: 20px;
                padding-right: 10px;
            }
        }
    }

    .ellipsis {
        text-align: left;
        width: 100%;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .selected {
        background-color: ${(props) => props.theme.hoverBackgroundColor};
    }
`
