
export const geojsonData = {};

export const geojsonSource = (layerId) => ({
  id: `data-${layerId}`,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  }
});

export const getLayerParams = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_layer_params/data__medical_1/`);
    return result.json();
};

export const getPlanTypes = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_plan_types/data__medical_1/`);
    return result.json();
};

export const getFloors = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_floors/data__medical_1/`);
    return result.json();
};

export const getAllPlans = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_plan/data__medical_1/`);
    return result.json();
};

export const getPlanGroupByType = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_plan__group_by_type/data__medical_1/`);
    return result.json();
};

export const getPlanGroupByDepartment = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_plan__group_by_department/data__medical_1/`);
    return result.json();
};

export const getRootCategories = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/get_plan_category/data__medical_1/`);
    return result.json();
};

export const getOuterBuildings = async () => {
    const url = `${process.env.REACT_APP_API_URL}/get_layer_geojson_on_the_fly/`;
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
    const body = JSON.stringify({ schema: 'data__medical_1', layer: 'outer__building' });
    const result = await fetch(url, { method: 'POST', headers, body });
    return result.json();
};

export const getOuterStreet = async () => {
    const url = `${process.env.REACT_APP_API_URL}/get_layer_geojson_on_the_fly/`;
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
    const body = JSON.stringify({ schema: 'data__medical_1', layer: 'outer__street' });
    const result = await fetch(url, { method: 'POST', headers, body });
    return result.json();
};

export const getOuterStreetName = async () => {
    const url = `${process.env.REACT_APP_API_URL}/get_layer_geojson_on_the_fly/`;
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
    const body = JSON.stringify({ schema: 'data__medical_1', layer: 'outer__street_name', fields: 'name,bearing' });
    const result = await fetch(url, { method: 'POST', headers, body });
    return result.json();
};
