
export const fetchRoute = data => {
  const url = `${process.env.REACT_APP_API_URL}/route/data__medical_1`;
  const method = 'POST';
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  return fetch(url, { method, headers, body: JSON.stringify(data) }).then(res => res.json());
};
