import React, { useEffect, useState, useMemo } from 'react';
import { bboxPolygon, booleanContains } from '@turf/turf';
import styled from 'styled-components';
import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SearchSelect from 'components/common/SearchSelect';
import Checkbox from 'components/common/Checkbox';
import useStateRef from 'hooks/useStateRef';
import { fetchRoute } from 'api/index';
import { initRoute, removeRoute, updateRoute, processRouteData } from 'utils/route';
import { showRouteSegment, hideRouteSegment, drawClickPoints } from 'utils/route';
import { filterPlanTypes, filterPlans, getClickPointFromSelected } from 'utils/route';
import { ReactComponent as IconRouteStraight } from 'img/icon-route-straight.svg';
import { ReactComponent as IconRouteLeft } from 'img/icon-route-left.svg';
import { ReactComponent as IconRouteRight } from 'img/icon-route-right.svg';


const MapRoute = ({ map, floor, setFloor, plans, planTypes }) => {
  const [routeData, setRouteData] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState(null);
  const [routeItems, setRouteItems] = useState(null);

  const rooms = useMemo(() => filterPlanTypes(planTypes), [planTypes]);
  const cabinets = useMemo(() => filterPlans(plans), [plans]);
  const searchItems = useMemo(() => [...rooms, ...cabinets], [rooms, cabinets]);

  const [selectedA, setSelectedA] = useState(null);
  const [selectedB, setSelectedB] = useState(null);
  const [isAccessible, setIsAccessible] = useState(false);

  const [canClickA, setCanClickA] = useState(false);
  const [canClickB, setCanClickB] = useState(false);
  const selectedARef = useStateRef(selectedA);
  const selectedBRef = useStateRef(selectedB);
  const canClickARef = useStateRef(canClickA);
  const canClickBRef = useStateRef(canClickB);

  const route = useMemo(() => routeData ? processRouteData(routeData) : null, [routeData]);

  const clickedPoints = useMemo(() => {
    if (route) return [];
    const featureA = getClickPointFromSelected(selectedA, 'start');
    const featureB = getClickPointFromSelected(selectedB, 'end');
    return [featureA, featureB].filter(Boolean);
  }, [route, selectedA, selectedB]);

  // prepare data to fetch new route
  useEffect(() => {
    if (!selectedA || !selectedB) return;
    setSelectedPoints({
      points: [selectedA.value, selectedB.value],
      is_accessible: isAccessible,
    });
    return () => {
      setSelectedPoints(null);
      setRouteData(null);
    }
  }, [
    selectedA,
    selectedB,
    isAccessible,
  ]);
  
  // fetch selected route
  useEffect(() => {
    if (!selectedPoints) return;
    fetchRoute(selectedPoints)
      .then(data => setRouteData(data.features ? data : null));
  }, [selectedPoints]);

  // show clicked points
  useEffect(() => {
    if (!map?.getStyle()) return;
    drawClickPoints(map, clickedPoints)
  }, [map, clickedPoints]);

  // update route geometry
  useEffect(() => {
    if (!map?.getStyle()) return;
    initRoute(map)
      .then(() => updateRoute(map, route, clickedPoints, floor));
    return () => {
      removeRoute(map);
    }
  }, [map, route, floor, clickedPoints]); // eslint-disable-line

  // init route handlers
  useEffect(() => {
    if (!map?.getStyle()) return;

    const clickPointsHandler = e => {
      const layers = ['plans-areas', 'plansGroupedByType-areas'];
      const features = map.queryRenderedFeatures(e.point, { layers });
      const f = (map.getZoom() >= 20.5 ? features[1] : features[0]) ?? features[0];
      if (!f) return;
      
      const canA = canClickARef.current && !selectedARef.current?.id.startsWith('click');
      const canB = canClickBRef.current && !selectedBRef.current?.id.startsWith('click');

      const coordinates = e.lngLat.toArray();
      const floor = f.properties.floor_id;
      const label = f.properties.name ?? f.properties.type_name;

      const newPoint = { id: `click_${f.id}`, value: { type: 'point', coordinates, floor_id: floor }, label };

      if (canA) setSelectedA(newPoint);
      if (canB) setSelectedB(newPoint);
    }

    const clickHandler = e => {
      const feature = e.features[0];
      const floor = feature.properties.to;
      setFloor(floor);
    }

    const hoverHandler = e => {
      const feature = e.features?.[0];
      if (!feature) return;
      map.setFeatureState(
        { source: 'route-stairs-elevator-active', id: feature.id },
        { hover: true },
      );
    }

    const leaveHandler = () => {
      map.removeFeatureState({ source: 'route-stairs-elevator-active' });
    }

    map.on('click', clickPointsHandler);
    map.on('click', 'route-stairs-elevator', clickHandler);
    map.on('mouseover', 'route-stairs-elevator-active', hoverHandler);
    map.on('mouseleave', 'route-stairs-elevator-active', leaveHandler);
    return () => {
      map.off('click', clickPointsHandler);
      map.off('click', 'route-stairs-elevator', clickHandler);
      map.off('mouseover', 'route-stairs-elevator-active', hoverHandler);
      map.off('mouseleave', 'route-stairs-elevator-active', leaveHandler);
    }
  }, [map]); // eslint-disable-line

  // update route items
  useEffect(() => {
    if (!route) return;
    setRouteItems(route.items.map(segment => ({ ...segment, checked: false })));
    return () => {
      setRouteItems(null);
    }
  }, [route]);

  const onPathSegmentClick = segment => {
    if (!segment.point) return;
    const bounds = bboxPolygon(map.getBounds().toArray().flat());
    const zoom = Math.max(map.getZoom(), 18.8);
    const segmentStart = segment.point.geometry.coordinates;
    const segmentFloor = segment.floor;
    const isVisible = booleanContains(bounds, segment.point);
    const sameFloor = floor === segmentFloor;
    if (!isVisible || true) map.flyTo({ center: segmentStart, zoom });
    if (!sameFloor) setFloor(segmentFloor);
  }

  const onSegmentChecked = segment => {
    const index = routeItems.findIndex(s => s.id === segment.id);
    const newRouteItems = [...routeItems.map((s, i) => ({ ...s, checked: i <= index }))];
    setRouteItems(newRouteItems);
  }

  return (
    <StyledMapRoute>
      <div className="route-controls">
        <div className="route-controls-item">
          <SearchSelect
            items={cabinets}
            selected={selectedA}
            placeholder="Point A"
            onChange={item => setSelectedA(item)}
            onCancel={() => setSelectedA(null)}
            onFocus={() => setCanClickA(true)}
            onBlur={() => setCanClickA(false)}
          />
          <div className="route-icon"><span>A</span></div>
        </div>
        {planTypes && (
          <div className="route-controls-item">
            <SearchSelect
              items={searchItems}
              selected={selectedB}
              placeholder="Point B"
              onChange={item => setSelectedB(item)}
              onCancel={() => setSelectedB(null)}
              onFocus={() => setCanClickB(true)}
              onBlur={() => setCanClickB(false)}
            />
            <div className="route-icon"><span>B</span></div>
          </div>
        )}
      </div>
      <div className="is-accessible">
        <Checkbox
          name="isAccessible"
          checked={isAccessible}
          label="Use an elevator only"
          onChange={e => setIsAccessible(e.target.checked)}
        />
      </div>
      {routeItems && (
        <div className="route-info">
          <div className="title">Route info</div>
          <div className="route">
            <OverlayScrollbarsComponent style={{ maxHeight: '250px' }}>
              {routeItems.map(segment => (
                <div
                  key={segment.id}
                  className={
                    classNames(
                      'route-item',
                      {
                        active: segment.floor === floor,
                        checked: segment.checked,
                      },
                    )
                  }
                  onMouseOver={() => showRouteSegment(map, segment)}
                  onMouseLeave={() => hideRouteSegment(map)}
                  onClick={() => onPathSegmentClick(segment)}
                >
                  <div className="route-text">
                    <Checkbox
                      name="route"
                      checked={segment.checked}
                      onChange={() => onSegmentChecked(segment)}
                    />
                    <div>{segment.text}</div>
                  </div>
                  <div className="route-icon">
                    {segment.icon === 'point-a' && <span>A</span>}
                    {segment.icon === 'point-b' && <span>B</span>}
                    {segment.icon === 'straight' && <IconRouteStraight />}
                    {segment.icon === 'left' && <IconRouteLeft />}
                    {segment.icon === 'right' && <IconRouteRight />}
                  </div>
                </div>
              ))}
            </OverlayScrollbarsComponent>
          </div>
        </div>
      )}
    </StyledMapRoute>
  );
};

const StyledMapRoute = styled.div`

  .route-controls, .route {
    position: relative;

    &:before {
      content: '';
      display: block;
      border-left: 1px dashed #BCBCBC;
      left: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }

  .route-controls-item:not(:last-child) {
    margin-bottom: 15px;
  }

  .route-controls-item {
    margin-left: 30px;
  }

  .route-item, .route-controls-item {
    position: relative;

    &:first-child::before, &:last-child:before {
      content: '';
      display: block;
      width: 2px;
      background-color: #FFF;
      position: absolute;
      right: calc(100% + 20px);
      transform: translateX(+50%);
    }

    &:first-child::before {
      top: 0;
      bottom: 50%;
    }

    &:last-child:before {
      top: 50%;
      bottom: 0;
    }

  }

  .route-controls-item > *:first-child {
    margin-bottom: 10px;
  }

  .route-info {
    user-select: none;
    
    .title {
      color: #1B203D;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin: 20px 0;
      margin-left: 4px;
    }

    .route-item {
      color: #BCBCBC;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      border-radius: 6px;
      margin-left: 20px;
      margin-right: 15px;
      cursor: pointer;

      .route-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &.checked {
        text-decoration: line-through;

      }

      &:not(.checked).active {
        color: #1B203D;
      }
      
      &:hover {
        background-color: #e3f0fd;
      }
    }

  }

  .route-item .route-icon {
    right: calc(100% + 10px);
  }

  .route-icon {
    color: #1B203D;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.16px;
    position: absolute;
    right: calc(100% + 20px);
    top: 50%;
    transform: translate(+50%, -50%);
    padding: 5px;
    background-color: #FFF;
    border-radius: 2px;

    path {
      fill: #BCBCBC;
    }
  }

  .is-accessible {
    margin: 20px 0 20px 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

`;

export default React.memo(MapRoute);
