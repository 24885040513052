import React from 'react';
import styled from 'styled-components';
import PlusIcon from '../../../img/plus.svg';
import MinusIcon from '../../../img/minus.svg';

const Controls = ({ map }) => {
  return (
    <StyledControls>
      <div className="button" onClick={() => map?.zoomIn()}>
        <img src={PlusIcon} alt="zoom-in" draggable="false"/>
      </div>
      <div className="button" onClick={() => map?.zoomOut()}>
        <img src={MinusIcon} alt="zoom-out" draggable="false"/>
      </div>
    </StyledControls>
  );
}

const StyledControls = styled.div`
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

  .button {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    box-shadow: rgba(114, 119, 143, 0.2) 0px 4px 20px;

    &:first-child {
      margin-bottom: 10px;
    }

    img {
      object-fit: contain;
      width: 16px;
      height: 16px;
    }

    &:hover {
        img path {
            fill: #007BFE;
        }
    }
  }

`;

export default Controls;
