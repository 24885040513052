import React, { useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import DropdownList from './DropdownList';
import _ from 'underscore';

const MapList = ({ planTypes, rootCategories, planTypesOnMap, allPlanTypes, items, floor, map }) => {
    return (
        <StyledMapList>
            { rootCategories.map((item, index) => {
                let subCategories = _.filter(planTypes, (elem) => (elem && elem.category_id) == item.id ),
                    baseColor = (item.id == 1) ? '#007BFE' : '#ED9082',
                    primaryColor = (item.id == 1) ? 'rgba(117, 172, 255, 0.3)' : 'rgba(237, 144, 130, 0.3)';

                return <DropdownList key={item.id} title={item.name} categories={subCategories} color={ baseColor } primaryColor={ primaryColor } planTypesOnMap={planTypesOnMap} items={items} floor={floor} map={map} />;
            })}
        </StyledMapList>
    );
};

const StyledMapList = styled.ul`
    padding: 0;
    margin: 0;
`;

export default MapList;
