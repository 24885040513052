import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from './constants/index';
import 'overlayscrollbars/overlayscrollbars.css';

import Map from './pages/Map';
import NotFound from './pages/NotFound';

function App() {
  return (
    <StyledApp>
      <BrowserRouter>
          <main>
              <Routes>
                <Route path={ROUTES.MAP} element={<Map />} />
                <Route path={ROUTES.LIST} element={<Map />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
          </main>
      </BrowserRouter>
    </StyledApp>
  );
};

const StyledApp = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  main {
    flex-grow: 1;
    overflow: auto;
  }

`;

export default App;

