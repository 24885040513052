import { point, featureCollection } from '@turf/turf';
import { getLineFirstCoord, getLineLastCoord } from 'utils/geometry';
import { mapLoadRasterizedImage } from 'utils/index';
import IconRouteStart from 'img/icon-route-start.svg';
import IconRouteEnd from 'img/icon-route-end.svg';
import IconElevatorUp from 'img/icon-elevator-up.svg';
import IconElevatorDown from 'img/icon-elevator-down.svg';
import IconStairsUp from 'img/icon-stairs-up.svg';
import IconStairsDown from 'img/icon-stairs-down.svg';
import IconElevatorUpActive from 'img/icon-elevator-up-active.svg';
import IconElevatorDownActive from 'img/icon-elevator-down-active.svg';
import IconStairsUpActive from 'img/icon-stairs-up-active.svg';
import IconStairsDownActive from 'img/icon-stairs-down-active.svg';
import IconWalk from 'img/icon-walk.svg';

const getFloorFilter = floor => ['==', 'floor', floor];

const getIconFromFlag = (feature) => {
  const flag = feature.properties.flag;
  if (feature.properties.id === 0) return 'point-a';
  if (/left/i.test(flag)) return 'left';
  if (/right/i.test(flag)) return 'right';
  if (/straight/i.test(flag)) return 'straight';
  return 'straight';
}

export const processRouteData = routeData => {
  const firstLineFeature = routeData.features[0];
  const lastLineFeature = routeData.features[routeData.features.length - 1];

  routeData.features.forEach(f => f.properties.floor = f.properties.floor_arr[0]);

  const startFeature = point(getLineFirstCoord(firstLineFeature), { ...firstLineFeature.properties, type: 'start' });
  const lastFeature = point(getLineLastCoord(lastLineFeature), { ...lastLineFeature.properties, type: 'end' });

  const points = featureCollection([startFeature, lastFeature]);

  const stairsElevators = featureCollection(
    routeData.features
      .filter(f => Object.keys(f.properties.floor_end).length)
      .map(f => ({ id: f.properties.id, ...point(getLineLastCoord(f), { ...f.properties, ...f.properties.floor_end }) }))
  );

  const stairsElevatorsExit = featureCollection(
    routeData.features
      .filter(f => Object.keys(f.properties.floor_start).length)
      .map(f => ({ id: f.properties.id, ...point(getLineFirstCoord(f), { ...f.properties, ...f.properties.floor_start }) }))
  );

  const items = [
    ...routeData.features.map(f => ({
      id: f.id,
      icon: getIconFromFlag(f),
      floor: f.properties.floor,
      text: f.properties.path_text,
      point: point(getLineFirstCoord(f), f.properties),
      line: f,
    })),
    {
      id: -1,
      icon: 'point-b',
      text: routeData.features[routeData.features.length - 1].properties.name,
      floor: routeData.features[routeData.features.length - 1].properties.floor,
      point: point(
        getLineLastCoord(routeData.features[routeData.features.length - 1]),
        routeData.features[routeData.features.length - 1].properties,
      ),
    }
  ];

  return { lines: routeData, points, stairsElevators, stairsElevatorsExit, items };
}

export const initRoute = async map => {

  await mapLoadRasterizedImage(map, 'icon-route-start', IconRouteStart);
  await mapLoadRasterizedImage(map, 'icon-route-end', IconRouteEnd);
  await mapLoadRasterizedImage(map, 'icon-elevator-up', IconElevatorUp);
  await mapLoadRasterizedImage(map, 'icon-elevator-down', IconElevatorDown);
  await mapLoadRasterizedImage(map, 'icon-stairs-up', IconStairsUp);
  await mapLoadRasterizedImage(map, 'icon-stairs-down', IconStairsDown);
  await mapLoadRasterizedImage(map, 'icon-elevator-up-active', IconElevatorUpActive);
  await mapLoadRasterizedImage(map, 'icon-elevator-down-active', IconElevatorDownActive);
  await mapLoadRasterizedImage(map, 'icon-stairs-up-active', IconStairsUpActive);
  await mapLoadRasterizedImage(map, 'icon-stairs-down-active', IconStairsDownActive);
  await mapLoadRasterizedImage(map, 'icon-walk', IconWalk);
  
  removeRoute(map);

  map.addSource('route', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-start-end', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-stairs-elevator', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-stairs-elevator-active', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-stairs-elevator-exit', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-points', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-lines', {
    type: 'geojson',
    data: null,
  });

  map.addSource('route-click-start-end', {
    type: 'geojson',
    data: null,
  });

  map.addLayer({
    id: 'route',
    source: 'route',
    type: 'line',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    paint: {
      'line-color': '#007BFE',
      'line-width': 3,
    }
  });

  map.addLayer({
    id: 'route-lines',
    source: 'route-lines',
    type: 'line',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    paint: {
      'line-color': '#6cd46c',
      'line-width': 3,
    }
  });

  map.addLayer({
    id: 'route-points',
    source: 'route-points',
    type: 'circle',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    paint: {
      'circle-color': '#6cd46c',
      'circle-stroke-color': '#FFF',
      'circle-radius': 5,
      'circle-stroke-width': 2,
    }
  });

  map.addLayer({
    id: 'route-stairs-elevator',
    source: 'route-stairs-elevator',
    type: 'symbol',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    layout: {
      'icon-image': [
        'case',
        ['all', ['==', ['get', 'type'], 'stairs'], ['==', ['get', 'direction'], 'up']], 'icon-stairs-up',
        ['all', ['==', ['get', 'type'], 'stairs'], ['==', ['get', 'direction'], 'down']], 'icon-stairs-down',
        ['all', ['==', ['get', 'type'], 'elevator'], ['==', ['get', 'direction'], 'up']], 'icon-elevator-up',
        ['all', ['==', ['get', 'type'], 'elevator'], ['==', ['get', 'direction'], 'down']], 'icon-elevator-down',
        'icon-stairs-up',
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 18, 0.1, 22, 0.8],
      'icon-rotation-alignment': 'map',
      'icon-ignore-placement': true,
    },
  });

  map.addLayer({
    id: 'route-stairs-elevator-active',
    source: 'route-stairs-elevator-active',
    type: 'symbol',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    layout: {
      'icon-image': [
        'case',
        ['all', ['==', ['get', 'type'], 'stairs'], ['==', ['get', 'direction'], 'up']], 'icon-stairs-up-active',
        ['all', ['==', ['get', 'type'], 'stairs'], ['==', ['get', 'direction'], 'down']], 'icon-stairs-down-active',
        ['all', ['==', ['get', 'type'], 'elevator'], ['==', ['get', 'direction'], 'up']], 'icon-elevator-up-active',
        ['all', ['==', ['get', 'type'], 'elevator'], ['==', ['get', 'direction'], 'down']], 'icon-elevator-down-active',
        'icon-stairs-up-active',
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 18, 0.1, 22, 0.8],
      'icon-rotation-alignment': 'map',
      'icon-ignore-placement': true,
    },
    paint: {
      'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0]
    }
  });

  map.addLayer({
    id: 'route-stairs-elevator-exit',
    source: 'route-stairs-elevator-exit',
    type: 'symbol',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    layout: {
      'icon-image': 'icon-walk',
      'icon-size': ['interpolate', ['linear'], ['zoom'], 18, 0.1, 22, 0.8],
      'icon-rotation-alignment': 'map',
      'icon-ignore-placement': true,
    },
  });

  map.addLayer({
    id: 'route-start-end',
    source: 'route-start-end',
    type: 'symbol',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'type'], 'start'],
        'icon-route-start',
        'icon-route-end',
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 18, 0.1, 22, 0.8],
      'icon-rotation-alignment': 'map',
    },
  });

  map.addLayer({
    id: 'route-click-start-end',
    source: 'route-click-start-end',
    type: 'symbol',
    minzoom: 18.8,
    filter: getFloorFilter(1),
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'type'], 'start'],
        'icon-route-start',
        'icon-route-end',
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 18, 0.1, 22, 0.8],
      'icon-rotation-alignment': 'map',
    },
  });

}

export const removeRoute = map => {
  const layers = [
    'route', 'route-start-end', 'route-stairs-elevator',
    'route-stairs-elevator-active', 'route-points', 'route-lines',
    'route-click-start-end', 'route-stairs-elevator-exit',
  ];
  layers.forEach(layer => {
    if (map.getLayer(layer)) map.removeLayer(layer);
    if (map.getSource(layer)) map.removeSource(layer);
  });
}

export const updateRoute = (map, route, clickedPoints, floor) => {
  const empty = featureCollection([]);
  map.getSource('route')?.setData(route?.lines ?? empty);
  map.getSource('route-start-end')?.setData(route?.points ?? empty);
  map.getSource('route-stairs-elevator')?.setData(route?.stairsElevators ?? empty);
  map.getSource('route-stairs-elevator-active')?.setData(route?.stairsElevators ?? empty);
  map.getSource('route-stairs-elevator-exit')?.setData(route?.stairsElevatorsExit ?? empty);
  map.getSource('route-points')?.setData(empty);
  map.getSource('route-lines')?.setData(empty);
  map.getSource('route-click-start-end')?.setData(featureCollection(clickedPoints));
  drawRoute(map, floor);
}

export const drawRoute = (map, floor) => {
  const filter = getFloorFilter(floor);
  if (map.getLayer('route')) map.setFilter('route', filter);
  if (map.getLayer('route-start-end')) map.setFilter('route-start-end', filter);
  if (map.getLayer('route-click-start-end')) map.setFilter('route-click-start-end', filter);
  if (map.getLayer('route-lines')) map.setFilter('route-lines', filter);
  if (map.getLayer('route-points')) map.setFilter('route-points', filter);
  if (map.getLayer('route-stairs-elevator')) map.setFilter('route-stairs-elevator', filter);
  if (map.getLayer('route-stairs-elevator-active')) map.setFilter('route-stairs-elevator-active', filter);
  if (map.getLayer('route-stairs-elevator-exit')) map.setFilter('route-stairs-elevator-exit', filter);
}

export const showRouteSegment = (map, routeItem) => {
  map.getSource('route-points')?.setData(routeItem.point ?? featureCollection([]));
  map.getSource('route-lines')?.setData(routeItem.line ?? featureCollection([]));
}

export const hideRouteSegment = map => {
  map.getSource('route-points')?.setData(featureCollection([]));
  map.getSource('route-lines')?.setData(featureCollection([]));
}

export const drawClickPoints = (map, points) => {
  map.getSource('route-click-start-end')?.setData(featureCollection(points ?? []));
}

export const getClickPointFromSelected = (selected, type) => (
  selected?.id.startsWith('click') ? (
    point(
      selected.value.coordinates,
      { floor: selected.value.floor_id, type }
    )
  ) : null
);

export const filterPlanTypes = planTypes => (
  planTypes
  .filter(p => p.category_id === 2 && p.id !== 55 && p.id !== 22)
  .map(p => ({
    id: `room_${p.id}`,
    value: { type: 'room_type', type_id: p.id },
    label: p.name,
  }))
);

export const filterPlans = plans => (
  plans
  .filter(p => p.properties.name)
  .map(({ properties }) => ({
    id: `cabinet_${properties.id}`,
    value: { type: 'cabinet', cabinet: properties.name },
    label: properties.name,
  }))
  .sort((a, b) => a.label - b.label)
);
