import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const Checkbox = ({ name, checked, label, onChange }) => {
  return (
    <StyledCheckbox
      className={classnames({ active: checked })}
    >
      <input
        name={name}
        type={'checkbox'}
        checked={checked}
        onChange={onChange}
      />
      <StyledCheckboxIcon checked={checked} />
      {label && (
        <div className="checkbox-label">
          <span>{label}</span>
        </div>
      )}
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  input {
    display: none;
  }

  .checkbox-label {
    color: #1B203D;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: 0.42px;
  }

`;

const StyledCheckboxIcon = styled.div`
  position: relative;
  overflow: hidden;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ checked }) => (checked ? '#007BFE' : '#1B203D')};
  border-radius: 30%;
  background-color: #fff;
  padding: 2px;
  margin-right: 10px;
  flex-shrink: 0;

  &::after {
    content: '';
    display: block;
    background-color: ${({ checked }) => (checked ? '#007BFE' : '#FFF')};
    border-radius: 30%;
    width: 100%;
    height: 100%;
  }
`;

export default Checkbox;
