import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filters: [],
    error: ''
  }

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilter: (state, action) => {
        state.status = 'succeeded';
        state.filters.push(action.payload);
        state.error = null;
    },
    deleteFilter: (state, action) => {
        state.status = 'succeeded';
        state.filters = state.filters.filter(item => item !== action.payload);
        state.error = null;
    },
    resetFilters: (state, action) => {
        state.status = 'succeeded';
        state.filters = [];
        state.error = null;
    }
  }
});

export const { addFilter, deleteFilter, resetFilters } = filtersSlice.actions;

export default filtersSlice.reducer;

