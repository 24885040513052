import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components'
import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ReactComponent as IconSearch } from 'img/icon-search.svg';
import { ReactComponent as IconClose } from 'img/icon-close.svg';

const Select = ({
  title,
  selected,
  items,
  placeholder,
  onChange,
  onCancel,
  onSearch,
  onFocus,
  onBlur,
  onBeforeBlur,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState({ selected: false, value: '' });
  const containerRef = useRef(null);

  const filteredList = useMemo(() => (
    items.filter(i => new RegExp(search.value.replaceAll('\\', '\\\\'), 'i').test(i.label))
  ), [search, items]);

  const open = useMemo(() => Boolean(search.value) && !search.selected, [search]);
  const hasItems = useMemo(() => filteredList.length > 0, [filteredList]);

  const onSelectSearch = e => {
    const value = e.target.value;
    setSearch({ selected: false, value });
    onSearch?.(value);
    if (!value) onCancel?.();
  }

  const onSearchCancel = () => {
    setSearch({ selected: false, value: '' });
    onCancel?.();
  }

  const onSearchFocus = useCallback(() => {
    setFocus(true);
    onFocus?.();
  }, [onFocus]);

  const onSearchBlur = useCallback(() => {
    setFocus(false);
    onBlur?.();
  }, [onBlur]);

  const onItemClick = (e, item) => {
    e.stopPropagation();
    onChange?.(item);
    onSearchBlur();
  }

  useEffect(() => {
    if (!selected) return;
    setSearch({ selected: true, value: selected.label });
  }, [selected]);

  useEffect(() => {
    const clickHandler = e => {
      const isInside = containerRef.current.contains(e.target);
      const isMapActive = e.target.className?.includes?.('mapboxgl-canvas');
      if (isInside) return onSearchFocus();
      if (!isInside && !isMapActive) return onSearchBlur();
    }
    document.body.addEventListener('click', clickHandler);
    return () => {
      document.body.removeEventListener('click', clickHandler);
    }
  }, [onSearchFocus, onSearchBlur]);

  return (
    <StyledSelect
      tabIndex={-1}
      ref={containerRef}
      onClick={onSearchFocus}
      className={classNames({ open: open && focus, focus })}
      {...rest}
    >
      {Boolean(title) && <div className="select-title">{title}</div>}
      <div className="select-input">
        <input
          value={search.value}
          placeholder={placeholder ?? 'Search'}
          onChange={onSelectSearch}
        />
        {!Boolean(search.value) && <div className="icon"><IconSearch /></div>}
        {Boolean(search.value) && <div onClick={onSearchCancel} className="icon active"><IconClose /></div>}
      </div>
      <div className="select-list">
      <OverlayScrollbarsComponent style={{ maxHeight: '250px' }} >
        {hasItems ? (
          filteredList.slice(0, 100).map(item => (
            <div
              key={item.id}
              className={classNames('select-list-item'/* , { active: selected.id === item.id } */)}
              onClick={e => onItemClick(e, item)}
            >
              {item.label}
            </div>
          ))
        ) : (
          <div
            className={classNames('select-list-item inactive')}
          >
            No results
          </div>
        )}
      </OverlayScrollbarsComponent>

      </div>
    </StyledSelect>
  )
}

const StyledSelect = styled.div`
  user-select: none;
  position: relative;

  &, & > * {
    outline: none;
  }

  .select-title {
    position: absolute;
    left: 10px;
    top: 0;
    background-color: #FFF;
    padding: 1px 5px;
    border-radius: 4px;
    z-index: 1;
    transform: translateY(-50%);

    color: #BCBCBC;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.56px;
  }

  .select-input {
    position: relative;
    padding: 15px 40px 15px 15px;

    input {
      all: unset;
      box-sizing: inherit;
      width: 100%;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scale(0.8);

      &.active {
        cursor: pointer;
        
        &:hover {
          path {
            fill: #007bfe;
            stroke: #007bfe;
          }
        }
      }
    }
  }

  .select-input, .select-list {
    border-radius: 10px;
    border: 1px solid #BCBCBC;
    background-color: #FFF;
    width: 100%;
  }

  .select-input, .select-list-item {
    color: #1B203D;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.45px;
  }

  .select-list-item {
    padding: 15px;

    &:not(.inactive):hover {
      background-color: #D8EBFE;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.inactive {
      color: #212121;
    }
  }

  .select-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: transparent;
  }

  &.open {
    .select-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent;
    }

    .select-list {
      display: block;
    }
  }
  
  &.focus {
    .select-input {
      border-top-color: #007bfe;;
      border-left-color: #007bfe;;
      border-right-color: #007bfe;;
    }

    &:not(.open) {
      .select-input {
        border-bottom-color: #007bfe;;
      }
    }

    .select-list {
      border-left-color: #007bfe;;
      border-right-color: #007bfe;;
      border-bottom-color: #007bfe;;
    }
  }

`;

export default Select;
