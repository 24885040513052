import { default as Fuse } from 'fuse.js'
import _ from 'underscore'

import React, {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  useEffect,
  useState
} from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { defaultFuseOptions, defaultTheme } from '../../../../config/config'
import { debounce } from '../../../../utils'
import Results, { Item } from './Results'
import SearchInput from './SearchInput'

export const DEFAULT_INPUT_DEBOUNCE = 200
export const MAX_RESULTS = 1000

export default function ReactSearchAutocomplete({
  items = [],
  title = '',
  fuseOptions = defaultFuseOptions,
  inputDebounce = DEFAULT_INPUT_DEBOUNCE,
  onSearch = () => {},
  onHover = () => {},
  onSelect = () => {},
  onFocus = () => {},
  onClear = () => {},
  showIcon = true,
  showClear = true,
  maxResults = MAX_RESULTS,
  placeholder = '',
  autoFocus = false,
  styling = {},
  resultStringKeyName = 'type_name',
  inputSearchString = '',
  formatResult,
  showNoResults = true,
  showNoResultsText = 'No results',
  showItemsOnFocus = false,
  maxLength = 0,
  className
}) {
  const theme = { ...defaultTheme, ...styling }
  const options = { ...defaultFuseOptions, ...fuseOptions }

  const fuse = new Fuse(items, options)

  fuse.setCollection(items)

  const [searchString, setSearchString] = useState(inputSearchString)
  const [results, setResults] = useState([])
  const [highlightedItem, setHighlightedItem] = useState(-1)
  const [isSearchComplete, setIsSearchComplete] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [showNoResultsFlag, setShowNoResultsFlag] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)

  useEffect(() => {
    setSearchString(inputSearchString)
    const timeoutId = setTimeout(() => setResults(fuseResults(inputSearchString)), 0)

    return () => clearTimeout(timeoutId)
  }, [inputSearchString])

  useEffect(() => {
    searchString.length > 0 &&
      results &&
      results.length > 0 &&
      setResults(fuseResults(searchString))
  }, [items])

  useEffect(() => {
    if (
      showNoResults &&
      searchString.length > 0 &&
      !isTyping &&
      results.length === 0 &&
      !isSearchComplete
    ) {
        setShowNoResultsFlag(true)
    } else {
        setShowNoResultsFlag(false)
    }
  }, [isTyping, showNoResults, isSearchComplete, searchString, results])

  useEffect(() => {
    if (showItemsOnFocus && results.length === 0 && searchString.length === 0 && hasFocus) {
        setResults(items.slice(0, maxResults))
    }
  }, [showItemsOnFocus, results, searchString, hasFocus])

  useEffect(() => {
    const handleDocumentClick = () => {
      eraseResults()
      setHasFocus(false)
    }

    document.addEventListener('click', handleDocumentClick)

    return () => document.removeEventListener('click', handleDocumentClick)
  }, [])

  const handleOnFocus = (event) => {
    onFocus(event)
    setHasFocus(true)
  }

  const callOnSearch = (keyword) => {
    let newResults = [];

    keyword.length > 0 && (newResults = fuseResults(keyword))

    setResults(newResults)
    onSearch(keyword, newResults)
    setIsTyping(false)
  }

  const handleOnSearch = React.useCallback(
    inputDebounce > 0
      ? debounce((keyword) => callOnSearch(keyword), inputDebounce)
      : (keyword) => callOnSearch(keyword),
    [items]
  )

  const handleOnClick = (result) => {
    eraseResults()
    onSelect(result)
    setSearchString(result.properties[resultStringKeyName])
    setHighlightedItem(0)
  }

  const fuseResults = (keyword) => {
    let newResults = fuse
            .search(keyword, { limit: maxResults })
            .map((result) => ({ ...result.item }))
            .slice(0, maxResults),
        uniqueResults = [];

        newResults.forEach((element) => {
            let isElemUniq = _.find(uniqueResults, function(elem) { return (elem.properties.type_id == element.properties.type_id && elem.properties.floor_id == element.properties.floor_id)  });

            if (!isElemUniq) {
                let findRelatedSpaces = _.filter(newResults, function(elem) { return (elem.properties.type_id == element.properties.type_id && elem.properties.floor_id == element.properties.floor_id)  }),
                    boundArea = findRelatedSpaces.map(elem => elem.properties.geom_centroid.coordinates),
                    rooms = findRelatedSpaces.map(elem => elem.properties.name).filter((room_number) => room_number);

                uniqueResults.push({ ...element, ...{ 'bound': boundArea, 'rooms': rooms }});
            }
        });

        return uniqueResults;
  }

  const handleSetSearchString = ({ target }) => {
    const keyword = target.value

    setSearchString(keyword)
    handleOnSearch(keyword)
    setIsTyping(true)

    if (isSearchComplete) {
        setIsSearchComplete(false)
    }
  }

  const eraseResults = () => {
    setResults([])
    setIsSearchComplete(true)
  }

  const handleSetHighlightedItem = ({
    index,
    event
  }) => {
    let itemIndex = -1

    const setValues = (index) => {
      setHighlightedItem(index)
      results[index] && onHover(results[index])
    }

    if (index !== undefined) {
      setHighlightedItem(index)
      results[index] && onHover(results[index])
    } else if (event) {
      switch (event.key) {
        case 'Enter':
          if (results.length > 0 && results[highlightedItem]) {
            event.preventDefault()
            onSelect(results[highlightedItem])
            setSearchString(results[highlightedItem].properties[resultStringKeyName])
            onSearch(results[highlightedItem].properties[resultStringKeyName], results)
          } else {
            onSearch(searchString, results)
          }
          setHighlightedItem(-1)
          eraseResults()
          break
        case 'ArrowUp':
          event.preventDefault()
          itemIndex = highlightedItem > -1 ? highlightedItem - 1 : results.length - 1
          setValues(itemIndex)
          break
        case 'ArrowDown':
          event.preventDefault()
          itemIndex = highlightedItem < results.length - 1 ? highlightedItem + 1 : -1
          setValues(itemIndex)
          break
        default:
          break
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledReactSearchAutocomplete className={className}>
        {/* <h1 className="title">{ title }</h1> */}
        <div className={`wrapper ${!isSearchComplete ? 'active' : ''}`}>
            <SearchInput
                searchString={searchString}
                setSearchString={handleSetSearchString}
                eraseResults={eraseResults}
                autoFocus={autoFocus}
                onFocus={handleOnFocus}
                onClear={onClear}
                placeholder={placeholder}
                showIcon={showIcon}
                showClear={showClear}
                setHighlightedItem={handleSetHighlightedItem}
                maxLength={maxLength}
            />
            <Results
                results={results}
                onClick={handleOnClick}
                setSearchString={setSearchString}
                showIcon={showIcon}
                maxResults={maxResults}
                resultStringKeyName={resultStringKeyName}
                formatResult={formatResult}
                highlightedItem={highlightedItem}
                setHighlightedItem={handleSetHighlightedItem}
                showNoResultsFlag={showNoResultsFlag}
                showNoResultsText={showNoResultsText}
            />
        </div>
      </StyledReactSearchAutocomplete>
    </ThemeProvider>
  )
}

const StyledReactSearchAutocomplete = styled.div`
    position: relative;

    height: ${(props) => parseInt(props.theme.height) + 2 + 'px'};
    width: ${(props) => parseInt(props.theme.width) + 'px'};
    margin: 0 auto;

    .title {
        transition: all 0.5s ease;
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
        color: #007BFE;
        margin-top: 0;
        letter-spacing: 0.3px;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;

        strong {
            color: #007BFE;
        }
    }

    .wrapper {
        overflow: hidden;
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: ${(props) => props.theme.borderRadius};

        box-shadow: ${(props) => props.theme.boxShadow};

        background-color: ${(props) => props.theme.backgroundColor};
        backdrop-filter: blur(25px);
        color: ${(props) => props.theme.color};

        font-size: ${(props) => props.theme.fontSize};
        font-family: ${(props) => props.theme.fontFamily};
        line-height: 1;

        z-index: ${(props) => props.theme.zIndex};
    }
`
