import React from 'react';
import styled from 'styled-components'

export const TabPane = ({ children, title }) => <StyledTabTitle className={title}>{ children }</StyledTabTitle>;

const StyledTabTitle = styled.div`
    position: relative;
    padding: 20px;
    z-index: 1;
    width: 360px;

    &.list {
        background-color: rgb(229, 236, 252);
        width: 100vw;
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        padding: 160px 50px;
        z-index: 9999;
    }

    &.map {
        border-radius: 20px;
        padding: 10px 0 10px 7px;
    }
`;

export default TabPane;
