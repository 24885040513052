import React from 'react';
import styled from 'styled-components';

function NotFoundPage() {
  return (
    <StyledNotFound><span>404</span></StyledNotFound>
  );
}

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
`;

export default NotFoundPage;
