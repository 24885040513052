import React from 'react';
import styled from 'styled-components';

import Tabs from './Tabs/Tabs';
import TabPane from './Tabs/TabPane';
import MapRoute from './Tabs/MapRoute';
import MapList from './Tabs/MapList';

import { ReactComponent as MapIcon } from '../../../img/icon-map.svg';
import { ReactComponent as RouteIcon } from '../../../img/icon-route.svg';

export const FiltersPanel = ({ map, allPlanTypes, planTypesOnMap, rootCategories, items, floor, setFloor, children }) => {
  return (
    <StyledFiltersPanel>
        { children }
        <Tabs preSelectedTabIndex={0}>
            <TabPane title="map" hrefUrl="/map" iconImage={<MapIcon />}>
                <MapList
                    planTypes={allPlanTypes}
                    rootCategories={rootCategories}
                    planTypesOnMap={planTypesOnMap}
                    items={items}
                    floor={floor}
                    map={map}
                />
            </TabPane>
            <TabPane title="route" hrefUrl="/map" iconImage={<RouteIcon />}>
                <MapRoute map={map} floor={floor} plans={items} setFloor={setFloor} planTypes={allPlanTypes} />
            </TabPane>
        </Tabs>
    </StyledFiltersPanel>
  )
}

const StyledFiltersPanel = styled.div``;

export default FiltersPanel;
