import React, { useRef, useState } from 'react';

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import styled, { ThemeProvider } from 'styled-components';
import { ReactComponent as IconArrow } from '../../../../img/icon-arrow.svg';

const AccordionItem = ({ title, children, theme }) => {
    const [clicked, setClicked] = useState(false);
    const contentEl = useRef();

    const handleToggle = () => {
        setClicked((prev) => !prev);
    };

  return (
        <ThemeProvider theme={theme}>
            <StyledAccordionItem className={`item ${clicked ? "active" : ""}`}>
                <div className="title" onClick={ handleToggle }>
                    { title }
                    <i className="control">
                        <IconArrow />
                    </i>
                </div>

                <OverlayScrollbarsComponent style={{ maxHeight: '250px', width: '327px' }} options={{ scrollbars: { autoHide: 'scroll' } }}>
                    <div
                        ref={contentEl}
                        className="wrapper"
                        style={
                            clicked
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0" }
                            }
                    >
                        <div className="content">{ children }</div>
                    </div>
                </OverlayScrollbarsComponent>
            </StyledAccordionItem>
        </ThemeProvider>
    );
};

const StyledAccordionItem = styled.div`
    position: relative;
    padding: 0 0 0 6px;
    margin: 0 0 0 15px;
    overflow: hidden;
    border-left: 2px solid transparent;
    list-style: none;
    &:before {
        transition: all 0.3s ease-in-out;
        content: '';
        border-left: 2px solid ${props => props.theme.baseColor};
        position: absolute;
        top: 30px;
        left: 4px;
        bottom: 0;
        height: 0;
   }

    &.active {
        margin-bottom: 30px;
        .title {
            font-weight: 700;
        }

        .control {
            transform: rotate(90deg);
        }

        &:before {
            height: calc(100% - 30px);
       }
    }

    .title {
        position: relative;
        font-weight: 500;
        line-height: 1;
        cursor: pointer;
        font-size: 16px;
        color: #1B203D;
        letter-spacing: 0.6px;
        padding: 15px 15px;
        white-space: nowrap;

        &:before {
            transform: translateY(-50%);
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            border-radius: 50%;
            left: -6px;
            background-color: ${props => props.theme.baseColor};
            z-index: 1;
        }
    }

    .control {
        transition: all 0.3s ease-in-out;
        top: 15px;
        right: 25px;
        position: absolute;
    }

    .wrapper {
        height: 0;
        overflow: hidden;
        transition: height ease 0.2s;
    }
`;

export default AccordionItem;
