import React from 'react';
import styled from 'styled-components';

const getFloorSuffix = floor => {
  if (floor === 1) return 'ST';
  if (floor === 2) return 'ND';
  if (floor === 3) return 'RD';
  return 'TH';
}

const Header = ({ floor }) => {
  return (
    <StyledHeader >
      <div className="title">MEDICAL CENTER</div>
      <div className="floor">{floor}{getFloorSuffix(floor)} FLOOR</div>
    </StyledHeader>
  );
};

const StyledHeader = styled.label`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #007BFE;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 20px rgba(114, 119, 143, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 15px;
  z-index: 1000;
  
  .title {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
  }

  .floor {
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.6px;
    border: 1px solid #007BFE;
    border-radius: 6px;
    padding: 5px;
    background-color: #FFF;
  }

`;


export default Header;
