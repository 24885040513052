import React, { useState, createContext } from 'react';
import styled from 'styled-components'

import TabTitle from './TabTitle';

export const TabContext = createContext('');

export const Tabs = (props) => {
  const { children, preSelectedTabIndex } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(preSelectedTabIndex || 0);

  return (
    <TabContext.Provider value={{ setSelectedTab: setSelectedTabIndex }}>
        <StyledTabs>
          <ul className="tabs">
            {children.map((item, index) => (
                <TabTitle
                    key={item.props.title}
                    title={item.props.title}
                    toHref={item.props.toHref}
                    iconImage={item.props.iconImage}
                    index={index}
                    isActive={index === selectedTabIndex}
                    setSelectedTab={setSelectedTabIndex}
                />
            ))}
          </ul>

          {children[selectedTabIndex]}
        </StyledTabs>
    </TabContext.Provider>
  );
};

const StyledTabs = styled.div`
    display: flex;
    flex-direction: column;
    .tabs {
        display: flex;
        margin-bottom: 0;
        margin-top: 10px;
        list-style: none;
        padding: 0;
        z-index: 2;
    }
`;

export default Tabs;
