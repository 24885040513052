import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import logger from 'redux-logger';
import {ThunkDispatch} from "@reduxjs/toolkit";
import thunkMiddleware from 'redux-thunk';

import filtersReducer from './../store/filtersSlice'

export const store =  configureStore({
  reducer: {
    filters: filtersReducer,
  },
  middleware: [logger, thunkMiddleware],
})

export function useAppDispatch() {
  return useDispatch();
}

export const useAppSelector = useSelector;
