import React, { useEffect, useState, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ROUTES } from './../../../../constants/index';
import AccordionItem from "./../Accordion/AccordionItem";
import * as turf from '@turf/turf';
import { useAppDispatch, useAppSelector } from './../../../../app/store';
import { classNames } from './../../../../utils';
import { addFilter, deleteFilter } from './../../../../store/filtersSlice';

const DropdownList = ({ title, categories, color, primaryColor, planTypesOnMap, items, floor, map }) => {
    const dispatch = useAppDispatch();

    const filtersList = useAppSelector((state) => state.filters.filters);

    const theme = {
        baseColor: color,
        primaryColor: primaryColor
    };

    const getFloors = (item) => (item.floor_name_json.length > 1) ? 'floors' : 'floor';

    const handleClick = useCallback((itemId) => {
        if (!(planTypesOnMap && planTypesOnMap.indexOf(itemId) > -1)) return;

        if (filtersList && filtersList.includes(itemId)) {
            dispatch(deleteFilter(itemId));
        } else {
            dispatch(addFilter(itemId));
        }
    }, [filtersList, planTypesOnMap]);

    return (
        <ThemeProvider theme={theme}>
            <StyledDropdownList>
                <AccordionItem title={ title } theme={ theme }>
                    { categories.map((item, index) => {
                        let liClasses = classNames({
                            'plan': true,
                            'active': filtersList && filtersList.indexOf(item.id) > -1,
                            'disabled': !(planTypesOnMap && planTypesOnMap.indexOf(item.id) > -1)
                        });
                        return (
                          <div className={liClasses} onClick={() => handleClick(item.id)} key={item.id}>
                            <div className="description">{item.name}</div>
                            <div className="floors">{ '/' + item.floor_name_json.join(',') + ' ' + getFloors(item) }</div>
                          </div>
                        );
                    })}
                </AccordionItem>
            </StyledDropdownList>
        </ThemeProvider>
    );
};

const StyledDropdownList = styled.div`
    border-top: 2px solid #fff;

    &:first-child {
        border-top: none;
    }

    .plan {
        background: transparent;
        padding: 5px 110px 5px 15px;
        cursor: pointer;
        text-align: left;
        position: relative;
        line-height: 1;
        border-radius: 5px;
        margin-right: 17px;

        .floors {
            background-color: ${props => props.theme.baseColor};
            color: #fff;
            border-radius: 5px;
            padding: 3px 6px;
            font-size: 14px;
            line-height: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            display: none;
        }

        .description {
            font-weight: 500;
            font-size: 14px;
            color: #000;
            line-height: 25px;
        }

        &.active {
            background-color: ${props => props.theme.primaryColor};
        }

        &.disabled {
            .description {
                color: rgba(0,0,0,0.5);
            }
        }

        &.active {
            .description {
                font-weight: 700;
            }

            .floors {
                display: block;
            }
        }
    }
`;

export default DropdownList;
