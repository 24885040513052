import React, { useCallback } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components'

const TabTitle = (props) => {
  const { title, hrefUrl, setSelectedTab, index, isActive, children, iconImage } = props;

  const handleOnClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <StyledTabTitle>
        <Link to={ hrefUrl } className={`tab ${isActive ? 'active' : ''}`} onClick={handleOnClick}>
            <span className="tab-icon">
                { iconImage }
            </span>
            <span className="tab-item">{title}</span>
        </Link>
    </StyledTabTitle>
  );
};

const StyledTabTitle = styled.div`
    display: flex;
    flex: 0 0 50%;
    .tab {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
        padding: 0 20px;
        line-height: 40px;
        text-align: center;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #000;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #CCCCCC;
        text-transform: uppercase;
        font-weight: 700;

        &.active {
            border-bottom-width: 3px;
            border-bottom-color: #007BFE;
            color: #007BFE;

            .tab-icon {
                path {
                    fill: #007BFE;
                }
            }
        }

        .tab-icon {
            line-height: 1;
            margin-right: 5px;
            path {
                fill: #000;
            }
        }
    }
`;

export default TabTitle;
